@charset 'utf-8';


@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Global
@import "global/accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";
@import "global/gutenberg";
@import "global/mixing";
@import "global/variable";
@import "global/new-global";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";
@import "modules/magnific-popup";
@import "modules/socialico";
@import "modules/font-awesome";
@import "modules/slick";
//@import "modules/slick-theme";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";

// Templates
@import "templates/front";
@import "templates/kitchen-sink";

//content-modules
@import "content-modules/banner";
@import "content-modules/subpageicon";
@import "content-modules/ctaicon";
@import "content-modules/ctatwo_colfullbg";
@import "content-modules/branding_list"; 
@import "content-modules/statistics"; 
@import "content-modules/testimonial";
@import "content-modules/logoslider";
@import "content-modules/ourpeople";
@import "content-modules/resource_slider";
@import "content-modules/roundimgtext";
@import "content-modules/stylelist_block";
@import "content-modules/accordionbox";
@import "content-modules/floatimg";
@import "content-modules/twocol_content";
@import "content-modules/overflowimg_text";
@import "content-modules/simple_listblock";
@import "content-modules/icon-with-content-block";
@import "content-modules/page_list_content_blocks";
@import "content-modules/number_list_content_blocks";
@import "content-modules/benefit_listing_block";
@import "content-modules/cpt_table_block";
@import "content-modules/cpt_regions_archive_block";
@import "content-modules/partnership_block";
@import "content-modules/faq_block";
@import "content-modules/table_block";
@import "content-modules/logos_block";
@import "content-modules/ordered_list_block";
@import "content-modules/columns_with_round_icons_block";
@import "content-modules/video_fullbox";
@import "content-modules/fifty_block";
@import "content-modules/mjpostlist";
@import "content-modules/web_single";
@import "content-modules/clientfpo";
@import "content-modules/moreclient";
@import "content-modules/subcircel_icon";
@import "content-modules/team-bio";
@import "content-modules/blog";
@import "content-modules/video_list";
@import "content-modules/press_release";
@import "content-modules/core_value";
@import "content-modules/instafeed";
@import "content-modules/contactpages";
@import "content-modules/new-banner";
@import "content-modules/new-whole_carousel";
@import "content-modules/new-statistics";
@import "content-modules/new-logoslider";
@import "content-modules/new-branding_list";