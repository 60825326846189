
.table_block{	    
    .top_txt{		
		margin: 0 auto rem-calc(28px);
		padding: rem-calc(0 103px);

        @include bp(lgmin) {
            margin: 0 auto rem-calc(58px); 
        }
		
		@include bp(tabletmd) {
			padding: rem-calc(5 77px);
		}

		@include bp(tablet) {
			padding: rem-calc(5 50px);
		}

		@include bp(sm) {
			padding: 0;
		}
		
		h2{
			@include bp(lgmin) {
				margin-bottom: rem-calc(21px);
			}
		}
	}    
    .secbgimg{
        &.pattern{
            background-size: cover !important;
        }            
    }           
    .two-col-table-wrapper{
        display:flex;
        @include bp(tabletmd){
           display:block;
        }
        .width-50{
            width:50%;
            border:1px solid $pale;
            @include bp(tabletmd){
               width:100%; 
            }
        }
        .table-col-left{
            &.width-50{
                border-right:none;
                @include bp(tabletmd){
                    border:1px solid $pale;
                    border-bottom: none;
                }
            }
        }
        table{
            border:none;  
            margin:0;
            tr{
                border-top:1px solid #a5a7aa;
                border-bottom:1px solid #a5a7aa;                
                &:nth-child(even){
                    background:$white-smoke;   
                }                
                &:last-child{
                    border-bottom:none;
                }  
            }
            thead{
                background: $orange;
                border:none;                
                tr{
                    border-top:none;
                    th{
                        color:$white;  
                        font-size:rem-calc(21);
                        font-family:$roboto;   
                        padding:rem-calc(5 32);
                        height:rem-calc(65);
                        @include bp(tabletmd){
                            padding:rem-calc(5 15);
                            font-size:rem-calc(20);
                            height:rem-calc(60);
                        }                        
                    }
                }                
            }
            tbody{
                tr{
                    td{
                        color:$darkgrey;
                        font-size:rem-calc(18);
                        font-family:$roboto;
                        padding:rem-calc(5 32);
                        height:rem-calc(65);
                        @include bp(tabletmd){
                            padding:rem-calc(5 15);
                            font-size:rem-calc(20);
                            height:rem-calc(60);
                        }
                        @include bp(tabletsm){                            
                            font-size:rem-calc(18);
                            height:rem-calc(55);
                            font-size:rem-calc(16);
                        }
                    }
                }
            }
        }
    }

    .tableblock_btn{
        margin-top: 20px;
    }
}
