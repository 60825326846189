.partnership-block{ 
	&.innerspacing{
		@include bp(lgmin) {
			padding:rem-calc(88px 0 76px);
		}
	}
    .top_txt{
        width:100%;
        max-width:rem-calc(900);
        margin:rem-calc(0) auto;
        margin-bottom: calc(20px + 4.8%);

        @include bp(sm) {
            margin-bottom: rem-calc(20px);
        }
        
        h2{
            @include bp(lgmin) {
                margin-bottom: rem-calc(12px);
            }
        }
    }
    .grid-container{
        @include bp(sm){
            padding: 0;
        }
    }
    ul{
        &.partnership-listing{
            margin: 0;
            li{
                list-style: none;
                margin-bottom: rem-calc(15);                
                display: flex;
                justify-content: flex-end;
                @include bp(sm) {
                  text-align: center;
                }
                .partnership-box{
                    display:flex;
                    align-items: center;   
                    -webkit-border-radius:rem-calc(180 0 0 179);
                    -moz-border-radius:rem-calc(180 0 0 179);
                    border-radius:rem-calc(180 0 0 179);  
                    width:100%;
                    max-width:rem-calc(915);
                    @include bp(sm){
                        display:block;
                        max-width:100%;
                        width:100%;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        border-radius: 0;
                        margin-top: 110px;
                    }
                    .partnership-thumb{
                        height: rem-calc(310px);
                        width: rem-calc(310px);
                        border-radius: 50%;
                        overflow: hidden;
                        background: $white;
                        margin: 0 auto;
                        margin-left:rem-calc(-10);
                        padding: rem-calc(15px);
                        transition:0.5s all;
                        display: flex;                             
                        @include bp(tabletmd) {
                            height: rem-calc(278px);
                            width: rem-calc(278px);
                        }
                        @include bp(tablet) {
                            padding: rem-calc(10px);
                            height: rem-calc(224px);
                            width: rem-calc(224px);
                        }
                        @include bp(sm) {
                            margin:rem-calc(-90) auto rem-calc(15);			
                        }
                        .partnershipimg{
                            height: rem-calc(280px); 
                            width: rem-calc(280px);
                            border-radius: 50%;
                            overflow: hidden;
                            transform: scale(1);
                            transition:0.5s all;
                            display: block;
                            @include bp(tabletmd) {
                                height: rem-calc(246px);
                                width: rem-calc(246px);
                            }
                            @include bp(tablet) {
                                height: rem-calc(204px);
                                width: rem-calc(204px);
                            }

                        }

                        &:hover{			
                            .round_personimg{
                                transform: scale(1.03);				
                            }
                        }
                    }
                    .partbnership-desc{
                        width: calc(100% - 300px);
                        padding-left: 95px;
                        padding-right: 50px;
                        @include bp(tabletmd){
                            width: calc(100% - 246px);
                            padding-left: 50px;
                            padding-right: 50px;
                        }
                        @include bp(tablet) {
                             width: calc(100% - 204px);
                        }
                        @include bp(sm) {
                            width: 100%;
                            padding:rem-calc(15 15 25);
                        }
                        h2{
                            color:$white;
                            @include bp(tabletmd) {
                                font-size:  rem-calc(24);
                            }
                            @include bp(tablet) {
                                font-size:  rem-calc(22);

                            }
                            @include bp(xsm) {
                                font-size:  rem-calc(20);
                            }
                        }
                        p{
                            color:$white;
                            a{
                                color:$white;
                                &:hover{
                                    color:$yellow;
                                }
                            }
                            @include bp(tabletsm) {
                                font-size:rem-calc(16);
                            }
                            @include bp(sm) {
                                margin-bottom: rem-calc(25);
                            }
                        }
                        a{
                            &.btn{
                                &:hover, &:focus{
                                    background: $white;
                                    border:rem-calc(1) solid $white;
                                }
                            }
                        
                        }
                    }

                }
                &:nth-child(even){
                    justify-content: flex-start;
                    @include bp(sm){
                        justify-content: center;
                    }
                    .partnership-box{
                        -webkit-border-radius:rem-calc(0 180 179 0); 
                        -moz-border-radius:rem-calc(0 180 179 0); 
                        border-radius:rem-calc(0 180 179 0); 
                        @include bp(sm){                            
                            -webkit-border-radius: 0;
                            -moz-border-radius: 0;
                            border-radius: 0;
                        }
                        .partnership-thumb{
                            order:2;
                            margin-right:rem-calc(-15);
                            margin-left:0;
                            @include bp(sm){
                                margin:rem-calc(-90) auto rem-calc(15);
                            }
                        }
                    }
                }
                
            }
        }
    }    
}