  
.mjpostlist{
	position: relative;
	z-index: 3;

	@include bp(xxlmin) {
		min-height: 30vh;
	}



	&.innerspacing{	
	padding-top: rem-calc(38px);	
		@include bp(lgmin) {
			padding: rem-calc(38px 0 105px);
		}
	}

	


	.top_select{
		text-align: center;
		margin: 0 auto rem-calc(10px);
		z-index: 1;

		@include bp(sm) {
			position: static;
		}
		.selet_item , .selet_item li{
			display: inline-block;
			vertical-align: baseline;
			margin: rem-calc(0 14px 0);

			select{
				width: rem-calc(311px);
				box-shadow: none;
				@include bp(tablet) {
					width: rem-calc(212px);
				}
			}

			@include bp(sm) {
				display: block;
				margin-bottom: rem-calc(10px);

			}
		}

		.selet_item ul{
			margin: 0;
		}
	}

	

	.large-6{
		display: flex;
		flex-wrap:wrap;
	}


	

}


.mjpost_box{
		padding: rem-calc(45px 0 56px);
		position: relative; 
		width: 100%;

		@include bp(tablet) {
			padding: rem-calc(35px 0 40px);
		}

		@include bp(sm) {
			padding: rem-calc(20px 0 0);
		}
		

		a{
			color: $darkgrey;
		}

		.mjpost_topline{
			font-size: rem-calc(16px);
			font-weight:$light; 
			margin-bottom: rem-calc(7px);
			text-transform: capitalize;
			
		}

		.mjpost_img{
			background: $yellow;
			padding: rem-calc(0px);
			transition:0.5s all;
			@include bp(tablet) {
				padding: rem-calc(8px);				
			}

			figure{
				padding: 0 0 36% 0;
				@include bp(tablet) {
					padding: 0 0 44% 0;
				}
			}
		}

		&:hover{
			.mjpost_img{
				background: $orange;
			}
		}

		.mjpost_txt{
			margin: rem-calc(18px 1px); 



			h4{
				margin-bottom: rem-calc(5px);
				@include bp(lgmin) {
					font-size: rem-calc(22px);

				}
			}

			p{
				font-size: rem-calc(18px);
				line-height: 1.4;
				@include bp(sm) {
					font-size:  rem-calc(16); 

				}
				
			}
		}

		.read_btn{
			
			position: absolute;
			bottom: rem-calc(21px);

			@include bp(tablet) {
				bottom: 0;
			}

			@include bp(sm) {
				position: static;
			}

			.btn{
				border-radius: 0;
				padding: rem-calc(10px 16px);
			}
		}
	}


.pagination{
	margin: rem-calc(0 0 80px 0);

	@include bp(lg) {
		margin: rem-calc(0 0 30px 0);
	}

	.wp-pagenavi{
		display: flex;
		flex-wrap:wrap;
		justify-content: flex-end;  

		> a , > span{
			border:1px solid $yellow;
			color: $blacktop;
			margin: rem-calc(10px 6px);
			font-weight: $bold;
			font-size: rem-calc(18px);
			padding: rem-calc(8px 14px);

			&:hover , &.current{
				background: $yellow;
				color: $blacktop;
				opacity: 1;
			}

			&.next , &.prev{
				border:1px solid transparent;
			}
		}
	}
}