.cpt-regions-archive-block{ 
	&.innerspacing{
		@include bp(lgmin) {
			padding:rem-calc(88px 0 76px);
		}
	}	
    .secbgimg{
        background-size: auto 100%!important;
        height: 100%;

        @include bp(xsm) {
            background-size: auto!important;
        }
    }
    .cpt-regions-archive-wrapper{
        margin-bottom: rem-calc(25);
        .region-listing-box{
            display: flex;
            flex-wrap: wrap;
            ul{
                &.region-listing{
                    width:33.33%;
                    padding:rem-calc(0 15);                    
                    margin: 0;   
                    @include bp(xsm){
                        width: 50%;
                        padding: 0;
                        margin: 0 0 25px;
                    }
                    @include bp(xxsm){
                        width: 100%;
                    }
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    li{
                        font-size:rem-calc(22);
                        color:$darkgrey;
                        font-weight:$bold;
                        font-family:$titilliumWeb;
                        margin-bottom: rem-calc(5);
                        list-style: none;                    
                        @include bp(lg) {
                            font-size:  rem-calc(20);
                        }
                        @include bp(tabletmd) {
                            font-size:  rem-calc(18);
                        }
                        @include bp(tablet) {
                            font-size:  rem-calc(17);
                            line-height: rem-calc(24);

                        }
                        @include bp(xsm) {
                            font-size:  rem-calc(16);
                            line-height: rem-calc(21);

                        }
                        a{
                            font-size:rem-calc(22);
                            color:$darkgrey;
                            font-weight:$bold;
                            font-family:$roboto;
                            &:hover{
                                 color:$orange;
                                 opacity: 1;
                            }
                            @include bp(lg) {
                                font-size:  rem-calc(20);
                            }
                            @include bp(tabletmd) {
                                font-size:  rem-calc(18);
                            }
                            @include bp(tablet) {
                                font-size:  rem-calc(17);
                                line-height: rem-calc(24);

                            }
                            @include bp(tabletsm) {
                                  font-size:  rem-calc(16);
                            }
                            @include bp(xsm) {                             
                                line-height: rem-calc(21);
                            }
                        }
                    }
                }
            }   
        }
    }
}