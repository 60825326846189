 
.form-section{
	h4{
		color: $orange;
	}

	.hbspt-form div.hs-form-field input, .hbspt-form div.hs-form-field select.hs-input{
		    max-width: 100%;
	}
}
.needsupport{

	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(63px 0 63px);
		}
	}

	.secbgimg.pattern{
		background-size: cover!important;
	}

	h2{
		@include bp(lgmin) {
			margin: rem-calc(0 0 20px 0);
		}
	}

	p{
		@extend .mdtxt;
	}

	.cnsupport_btn{
		margin: rem-calc(30px 0 0);

		.btn{
			@include bp(lgmin) {
				padding: rem-calc(11px 30px);
			}
		}
	}

	.needsupport_txt{
		padding:rem-calc( 0 15px);
	}

	
}

.contact_detail{
    &.innerspacing{		
    	padding-bottom: 0;
		@include bp(lgmin) {
			padding: rem-calc(71px 0 0);
		}

		@include bp(lg) {
			padding-bottom: 0; 
		}
	}

	h3{
		color: $orange;
		margin-bottom: 0;
		@include bp(lgmin) {
			font-size: rem-calc(25px); 
		}
	}

	h4{
		margin-bottom: 0;
		a{
			color: $darkgrey;
			transition:0.5s all;

			&:hover{
				color: $orange;
			}
		}
	}

	.cndetail_box{
		margin: 0 0 calc(20px + 6%) 0;

		@include bp(sm) {
			margin: rem-calc(0 0 20px 0);
		}
	}
}


.contactform{
	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(80px 0 63px);
		}
	}

	.top_txt{
		margin-bottom: rem-calc(50px);
		
		@include bp(sm) {
			margin-bottom: rem-calc(30px);
		}
	}

	.hbspt-form{
		max-width: rem-calc(500px);
		margin: 0 auto;
	}


	.submit_boxtxt{
		background: #f0f0f0;
		padding: rem-calc(38px 20px);
		margin-top: rem-calc(50px);

		@include bp(sm) {
			padding: rem-calc(20px 15px);
		}

		h2{
			color: $orange;
			margin-bottom: rem-calc(8px);
		}
		p{
			line-height: 1.4;

			small{
				font-size: rem-calc(18px);

				@include bp(sm) {
					font-size:  rem-calc(16);
				}
				
			}
		}

		hr{
			max-width: rem-calc(55px);
			margin: 0 auto rem-calc(10px);
			border-bottom: 1px solid $darkgrey;
		}

		.blg_btn{
			margin: rem-calc(28px 0 0);
			.btn{
				@include bp(lgmin) {
					padding: rem-calc(11px 30px);
				}
			}
		}
	}


}

.footer_mail{
	.hbspt-form{
		width: 100%;
		.hs-form{
			/*display: flex;
			flex-wrap:wrap;*/
			position: relative;
			padding: rem-calc(0 92px 0 0);

			@include bp(tabletmd) {
				padding: rem-calc(0 82px 0 0);
			}

			.hs-main-font-element{
				display: none;
			}

			div.hs-form-field{
				width: calc(100% - 90px);
				width: 100%;

				input{
					margin:rem-calc( 0 0 10px);
					box-sizing: border-box;
					width: 100%;
					border:none!important;

					@include bp(sm) {
						height:rem-calc( 36px);
					}
				}
			}

			.hs_submit{
				position: absolute;
				right:0;
				top: 0;
				.actions{
					margin: 0;
					padding: 0;
				}

				input[type="submit"]{
					background: $white;
					color: $orange;
					border:none!important;
					padding: rem-calc(12px 16px);
					margin: rem-calc(0 0 0 14px);
					@include bp(sm) {
						padding:rem-calc(9px 16px);
					}

					&:hover{
						background: $orange;
						color: $white;
					}
				}
			}
		}
		
	}
}



.hbspt-form{
	.hs-error-msgs{
		list-style:none;
		margin: rem-calc(10px 5px 20px);
		color: #d56c2d;

		label{
			color: #d56c2d;
		}
	}
	div.hs-form-field{
		margin-bottom: rem-calc(8px); 

		&.hs_recaptcha{
			margin-bottom: rem-calc(30px);
		}
		> label{
			display: none;
		}

		select.hs-input{
			background: transparent;
			@include background-triangle($orange);
			background-repeat: no-repeat;
			opacity: 1;
			background-position: right center;
			background-size: rem-calc(10px);
		}
		input , select.hs-input{
			margin: rem-calc(22px 0 0);
			padding: rem-calc(4px 0 10px);
			border:none!important;
			box-shadow: none;
			border-bottom: 1px solid #4a5155!important;
			border-radius: 0;
			font-size: rem-calc(18px);
			font-family: $titilliumWeb;
			height: auto;
			width: 100%;
			line-height: 1.1; 	

		}

		input::-webkit-input-placeholder , textarea::-webkit-input-placeholder {color: #898786;font-size: 18px;font-family: $titilliumWeb;}
		input::-moz-placeholder , textarea::-moz-placeholder {color: #898786;font-size: 18px;font-family: $titilliumWeb;}
		input:-ms-input-placeholder , textarea:-ms-input-placeholder{color: #898786;font-size: 18px;font-family: $titilliumWeb;}
		input:-moz-placeholder , textarea:-moz-placeholder {color: #898786;font-size: 18px;font-family: $titilliumWeb;}


		@include bp(sm) {
			input::-webkit-input-placeholder , textarea::-webkit-input-placeholder {font-size: 16px;}
			input::-moz-placeholder , textarea::-moz-placeholder {font-size: 16px;}
			input:-ms-input-placeholder , textarea:-ms-input-placeholder{font-size: 16px;}
			input:-moz-placeholder , textarea:-moz-placeholder {font-size: 16px;}
		}

		.hs-error-msg{
			color: $orange;
		}


		.inputs-list{
			list-style:none;
			margin: rem-calc(10px 0 0);
		}

		textarea.hs-input{
			border: 1px solid #4a5155!important;
			box-shadow: none;
			border-radius: 0;
			width: calc(100% - 8px);
			min-height: 100px;
			margin: rem-calc(20px 0 0 0);
			width: 100%;
		}


		&.hs-fieldtype-checkbox{
			.hs-form-checkbox{
				position: relative;
				padding: rem-calc(0 0 0 18px);
				line-height: 1;
				min-height: rem-calc(25px);
				cursor: pointer;

				/*padding: 0 20px 0 18px;
				display: inline-block;
				width: auto;*/

				input{
					display: inline-block;
					width: auto;
					position: absolute;
					top: 3px;
					left: 0;
					opacity: 0;
				}

				input + span:before{
					content: "";
					display: inline-block;
					background:#fff;
					width: rem-calc(12px);
					height:rem-calc( 12px);
					position: absolute;
					left: 0;
					top: rem-calc(6px);
					border: 1px solid $darkgrey;
				}

				input + span:after{
					content: "";
					display: inline-block;
					position: absolute;
					width: rem-calc(6px);
					height: rem-calc(6px);
					left: rem-calc(3px);
					top: rem-calc(9px);
					background: url(../images/icons/orangelist_tick.png);
					background:$orange;
					background-size: 100%;
					opacity: 0;
				}

				input:checked + span:after{
					opacity: 1;
				}
			}
		}
	}

	input[type="submit"]{
		@extend .btn; 
		text-shadow: none;
		box-shadow: none;
		margin: 0 auto;
		display: block;

		&:hover{
			box-shadow: none !important;
			border:1px solid $orange!important;
		}
	}
}

