
.new-branding_list{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(113px 0 70px);
		}
	}


	.brand-cell {
		padding-right: rem-calc(85);
		@include bp(tabletXl) {
			padding-right: 0;
		}
		&:nth-child(2n) {
			padding-left: rem-calc(25);
			padding-right: rem-calc(50);
			@include bp(tabletXl) {
				padding-left: 0;
			}
		}

		.brand-content {
			padding-right: rem-calc(5);
			@include bp(tabletXl) {
				max-width: 80%;
			}
			@include bp(sm) {
				max-width: 80%;
			}
			@include bp(xsm) {
				max-width: 60%;
			}
		}
		
		.brand-title {
			padding-bottom: rem-calc(5);
			padding-left: rem-calc(25);
			@include bp(tabletXl) {
				font-size: rem-calc(22);
			}
		}

		.brand-copy {
			padding-left: rem-calc(25);
			p {
				margin-bottom: 0 !important;
			}
		}
		.brand-item {
			padding-bottom: rem-calc(50);
			@include bp(sm) {
				padding-bottom: rem-calc(88);
			}
		}

	}

	.brand_icon {
		align-self: center;
		padding-right: rem-calc(10);
		// img {
			width: 80px;
			// @include bp(lg) {
			// 	max-width: 70px;
			// }
			@include bp(tabletXl) {
				width: 70px;
			}
			// @include bp(tablet) {
			// 	max-width: 70px;
			// }
			// @include bp(xsm) {
			// 	width: 50px;
			// }
			// @include bp(xxsm) {
			// 	width: 40px;
			// }
		// }
	}

	.pattern{
		background-repeat: no-repeat !important; 
	}

	.top_txt{
		@include bp(lgmin) {
			margin-bottom: rem-calc(34px);
		}
		
		h2{
			text-transform: uppercase;
			@include bp(lgmin) {
				margin-bottom: rem-calc(11px);
			}
		}
	}

	.brd_listbox{
		position: relative;
		padding: rem-calc(0 0 0 117px);
		margin: rem-calc(30px 0 0);

		@include bp(tabletmd) {
			padding: rem-calc(0 0 0 77px);
		}

		@include bp(tablet) {
			padding: rem-calc(0 0 0 50px);
		}

		@include bp(sm) {
			margin: rem-calc(20px 0 0);
		}

		.brd_listico{
			position: absolute;
			left: rem-calc(44px);
			top: 0;
			width: rem-calc(42px);

			@include bp(tabletmd) {
				left: rem-calc(22px);
			}
			@include bp(tablet) {
				width: rem-calc(32px);
				left: rem-calc(11px);
			}
		}

		h3{
			text-transform: capitalize;
			line-height: 1.2;
			@include bp(lgmin) {
				font-size: rem-calc(25px);
				margin-bottom: 3px;
			}
		}

		p{
			@include bp(lgmin) {
				line-height: 1.5;
			}
		}
	}

	.brd_btn{
		margin-top: rem-calc(23px);

		.btn{
			color: $white;
		}
	}
	

}
