
.styled_list{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(85px 0 55px);
		}
	}



	.pattern{
		background-repeat: no-repeat !important; 
		background-size: cover!important;
		height: 100%;
	}

	.top_txt{		
		margin: 0 auto rem-calc(28px);
		padding: rem-calc(0 103px);
		
		@include bp(tabletmd) {
			padding: rem-calc(0 77px);
		}

		@include bp(tablet) {
			padding: rem-calc(0 50px);
		}

		@include bp(sm) {
			padding: 0;
		}
		
		h2{
			@include bp(lgmin) {
				margin-bottom: rem-calc(21px);
			}
		}
	}

	.sty_listbox{
		position: relative;
		padding: rem-calc(3px 0 0 103px);
		margin: rem-calc(25px 0 0); 

		@include bp(tabletmd) {
			padding: rem-calc(0 0 0 77px);
		}

		@include bp(tablet) {
			padding: rem-calc(0 0 0 50px);
		}

		@include bp(sm) {
			margin: rem-calc(20px 0 0);
			padding: rem-calc(0 0 0 36px);
		}

		.sty_listico{
			position: absolute;
			left: rem-calc(44px);
			top: rem-calc(-5px);
			width: rem-calc(44px);

			@include bp(tabletmd) {
				left: rem-calc(22px);
			}
			@include bp(tablet) {
				width: rem-calc(32px);
				left: rem-calc(11px);
			}
			@include bp(sm) {
				left: rem-calc(0);
			}

		}

		h4{
			margin-bottom: rem-calc(10px);
			@include bp(lgmin) {
				font-size: rem-calc(22px);
			}
		}

		p{
			@include bp(lgmin) {
				line-height: 1.5;
			}
		}

		ul{
			@extend .bulletlist;
		}
	}

	.sty_btn{
		margin-top: rem-calc(23px);
		@include bp(lgmin) {
			margin-top: rem-calc(41px);
		}

	}
	

}
