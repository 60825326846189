
.ctatwo_colfull{
	position: relative;
	z-index: 1;

	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(62px 0 82px);
		}
		@include bp(sm) {
			padding-top: 0;
		}
	}

	.ctatwo_colfullbg{
		position: absolute;
		background-repeat: repeat !important;
		background-position: right top !important;
		right: 50%;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
	}

	.grid-x{
		.cell:first-child{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			justify-content: flex-end;
			margin-bottom: rem-calc(30px);
			.ctatwo_colfullbox{
				text-align: right;
				padding-right: 15%;

				@include bp(tablet) {
					padding-right: 0;
				}

				@include bp(sm) {
					padding: rem-calc(30px 15px 30px);
					margin: rem-calc(0 -10px);
					text-align: center;
					position: relative;

					.ctatwo_colfullbg{
						right: 0;
					}
				}
			}
		}

		.cell:last-child{
			.ctatwo_colfullbox{
				padding: rem-calc(6px) 0 0 11%;
				@include bp(tablet) {
					padding: 0;
				}
			}
		}
	}

	h2{
	   @include bp(lgmin) {
		   	font-size: rem-calc(42px);
		   	line-height: 1.2;
		   	margin-bottom: rem-calc(24px);
	   }
	}

	h3{
		font-style: italic;
		font-weight: $bold!important;
		@include bp(lgmin) {
			font-size: rem-calc(25px);
			line-height: 1.2;
		}
	}

	p{
		line-height: 1.43;
		letter-spacing: -0.2px;
	}

	.ctatwo_colfullbox{
		.tickink_green{
			margin-top: 6%;
			

			.tickico{
				position: absolute; 				
			}

			
		}
	}


}

.tickink_green{
	position: relative;
	padding: rem-calc(3px 0 0 46px);
	@include bp(sm) {
		padding: rem-calc(3px 0 0 40px);
	}
	.tickico{		
		width: rem-calc(42px);
		display: inline-block;
		vertical-align: top;
		top: rem-calc(-4px);
		left: rem-calc(-4px);

		@include bp(sm) {
			top: rem-calc(-8px);
		}

	}	
	a{
		font-weight: $bold;
		text-transform: uppercase;
		color:$green-txt;
		font-size: rem-calc(17px);

		@include bp(sm) {
			font-size: rem-calc(15px);
		}
	}
}
