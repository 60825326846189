.new-global {

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
        margin-bottom: rem-calc(18);
        font-family: $titilliumWeb;
        line-height: normal;
        font-weight: $light;
        @include bp(tabletmd) {
            > br {
                display: none;
            }
        }
    }

    h1 {
        font-size: rem-calc(65);
        line-height: rem-calc(65);
        font-weight: $bold;
        margin-bottom: rem-calc(11px);
        text-transform: initial;
        font-family: $titilliumWeb;

        @include bp(lg) {
            // font-size: rem-calc(60);
            // line-height: rem-calc(52);
        }
        @include bp(tabletmd) {
            // font-size: rem-calc(50);
            // line-height: normal;
        }
        @include bp(tablet) {
            // font-size: rem-calc(40);

        }
        @include bp(xsm) {
            font-size: rem-calc(60);
        }
    }


    h2 {
        font-size: rem-calc(28);
        line-height: rem-calc(34);
        margin-bottom: rem-calc(16);
        font-weight: $bold;
        font-family: $titilliumWeb;
        letter-spacing: -0.5px;

        @include bp(lg) {
            // font-size: rem-calc(30);
        }
        @include bp(tabletmd) {
            // font-size: rem-calc(28);
            // line-height: normal;

            > br {
                display: none;
            }
        }
        @include bp(tablet) {
            // font-size: rem-calc(26);
            // margin-bottom: rem-calc(12);

        }
        @include bp(xsm) {
            // font-size: rem-calc(20);
            // margin-bottom: rem-calc(10);

        }
    }

    .h2big {
        font-size: rem-calc(50px);
        @include bp(lg) {
            font-size: rem-calc(45);
        }
        @include bp(tabletmd) {
            font-size: rem-calc(40);
            line-height: normal;

            > br {
                display: none;
            }
        }
        @include bp(tablet) {
            font-size: rem-calc(35);
        }
        @include bp(xsm) {
            font-size: rem-calc(30);
        }
    }

    h3 {
        font-weight: $mediumdark;
        font-size: rem-calc(22);
        line-height: rem-calc(36);
        margin-bottom: 0;
        font-family: $titilliumWeb;

        @include bp(lg) {
            font-size: rem-calc(23);
            line-height: rem-calc(32);

            > br {
                display: none;
            }
        }
        @include bp(tabletmd) {
            font-size: rem-calc(21);
            line-height: normal;
        }
        @include bp(tablet) {
            font-size: rem-calc(20);

        }
        @include bp(xsm) {
            font-size: rem-calc(18);
        }
    }

    h4 {
        font-family: $titilliumWeb;
        font-size: rem-calc(19);
        font-weight: $bold;
        line-height: rem-calc(28);
        color: $white;
        margin-bottom: rem-calc(20);

        @include bp(lg) {
            font-size: rem-calc(19);
        }
        @include bp(tabletmd) {
            font-size: rem-calc(18);
        }
        @include bp(tablet) {
            font-size: rem-calc(17);
            line-height: rem-calc(24);

        }
        @include bp(xsm) {
            font-size: rem-calc(16);
            line-height: rem-calc(21);
        }
    }
}

.hero_body {
    font-family: $roboto;
    font-weight: $normal !important;
    font-size: rem-calc(22);
    line-height: rem-calc(31);
    p {
        @extend .hero_body;
    }
    
    @include bp(lg) {
        // font-size: rem-calc(60);
        // line-height: rem-calc(52);
    }
    @include bp(tabletmd) {
        // font-size: rem-calc(50);
        // line-height: normal;
    }
    @include bp(tablet) {
        // font-size: rem-calc(40);

    }
    @include bp(xsm) {
        // font-size: rem-calc(60);
    }
}

.body_copy {
    font-family: $roboto;
    font-size: rem-calc(17);
    line-height: rem-calc(22);
    font-weight: $normal;
    p {
        @extend .body_copy;
        margin: 0;
        padding: 0;
    }
}

.small_copy {
    font-family: $roboto;
    font-size: rem-calc(14);
    font-weight: $normal;
    p {
        @extend .small_copy
    }
}

.header-underline {
    display: inline-block;
    position: relative;
    margin-bottom: rem-calc(64) !important;
    @include bp(lg) {
        
    }
    @include bp(tablet) {
        
    }
    @include bp(tabletmd) {
        margin-bottom: rem-calc(32);
    }
    @include bp(xsm) {
        margin-bottom: rem-calc(32);
    }
}

.header-underline:after {
    content: "";
    height: 5px;
    width: 15%;
    background-color: $yellow;
    position: absolute;
    bottom: rem-calc(-32);
    left: 50%;
    transform: translate(-50%);
    border-radius: 5px;

    @include bp(lg) {
        
    }
    @include bp(tablet) {
        
    }
    @include bp(tabletmd) {
        bottom: rem-calc(-16);
    }
    @include bp(xsm) {
        bottom: rem-calc(-16);
    }
}

.header-underline-left {
    display: inline-block;
    position: relative;
    margin-bottom: rem-calc(64) !important;
}

.header-underline-left:after {
    content: "";
    height: 5px;
    width: 13%;
    background-color: $yellow;
    position: absolute;
    bottom: rem-calc(-32);
    left: 16px;
    transform: translate(-50%);
    border-radius: 5px;
}

.section-padding-top {
    padding-top: rem-calc(134);

    @include bp(lg) {
        
    }
    @include bp(tablet) {
        
    }
    @include bp(tabletmd) {
        padding-top: rem-calc(126);
    }
    @include bp(xsm) {
        padding-top: rem-calc(126);
    }

    &.small {
        padding-top: rem-calc(68);
        @include bp(lg) {
            
        }
        @include bp(tablet) {
            
        }
        @include bp(tabletmd) {
            padding-top: rem-calc(72);
        }
        @include bp(xsm) {
            padding-top: rem-calc(72);
        }
    }
}

.section-padding-bottom {
    padding-bottom: rem-calc(134);

    @include bp(lg) {
        
    }
    @include bp(tablet) {
        
    }
    @include bp(tabletmd) {
        padding-bottom: rem-calc(126);
    }
    @include bp(xsm) {
        padding-bottom: rem-calc(126);
    }
    &.small {
        padding-bottom: rem-calc(68);
        @include bp(lg) {
            
        }
        @include bp(tablet) {
            
        }
        @include bp(tabletmd) {
            padding-bottom: rem-calc(72);
        }
        @include bp(xsm) {
            padding-bottom: rem-calc(72);
        }
    }
}

.btn-padding-top {
    padding-top: rem-calc(50);
    @include bp(lg) {
            
    }
    @include bp(tablet) {
        
    }
    @include bp(tabletmd) {
        padding-top: rem-calc(68);
    }
    @include bp(xsm) {
        padding-top: rem-calc(68);
    }
}

.btn-padding-bottom {
    padding-bottom: rem-calc(50);
    @include bp(lg) {
            
    }
    @include bp(tablet) {
        
    }
    @include bp(tabletmd) {
        padding-bottom: rem-calc(68);
    }
    @include bp(xsm) {
        padding-bottom: rem-calc(68);
    }
}