 .clientfpo{
	.banner.inner_bnr{

		@include bp(lgmin) {		
			padding: rem-calc(44px 0 90px);
		}

		@include bp(tabletmd) {
			padding-bottom: rem-calc(80px);
		}
		@include bp(tablet) {
			padding-bottom: rem-calc(70px);

		}
		@include bp(sm) {
			padding-bottom: rem-calc(60px);
		}
	}

	&.bgsecimg{
		overflow: visible;
		    margin: rem-calc(120px 0 0);
		    position: relative;
		    padding: rem-calc(215px 0 51px);

		    @include bp(tabletmd) {
		    	margin: rem-calc(100px 0 0);
		    	padding: rem-calc(215px 0 51px);
		    }
		    @include bp(tablet) {
		    	margin: rem-calc(80px 0 0);
		    	padding: rem-calc(215px 0 41px);

		    }
		    @include bp(sm) {
		    	margin: rem-calc(60px 0 0);
		    	padding: rem-calc(134px 0 31px);
		    }
	}

	.grid-container{
		max-width: rem-calc(860px);
	}

	h2{
		@include bp(lgmin) {
			margin-bottom: rem-calc(25px);
		}
	}

	p{
		@extend .mdtxt;
	}

	.clientfpo_btn{
		margin-top: rem-calc(35px);
	}

	.secbgimg.pattern{
		background-size: cover !important;
	}

		.fpo_img{
			max-width: rem-calc(690px);
			margin: 0 auto;
			top: rem-calc(-124px);
			position: absolute;
			left: 0;
			right: 0;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				left:50%;
				transform : translateX(-50%);
			}

			@include bp(tabletmd) {
		    	top: rem-calc(-114px);
		    	
		    }

			@include bp(tablet) {
				top: rem-calc(-100px);
				margin:rem-calc( 0 15px);
			}

		    @include bp(sm) {
		    	top:rem-calc( -90px);
		    }

			.fpo_bg{
				height: calc(100% - 32px);
				width: 	calc(100% - 32px); 

				@include bp(sm) {
					height: calc(100% - 16px);
					width: 	calc(100% - 16px);
				}
			}

			h2{
				margin: rem-calc(66px 0);
			}
	}
}

.clientstory.opentext{
	&.innerspacing{
		padding-bottom: rem-calc(56px);


		@include bp(lgmin) {
			padding: rem-calc(76px 0 56px);
		}

		@include bp(tabletmd) {
			padding-bottom: rem-calc(46px);
		}
		@include bp(tablet) {
			padding-bottom: rem-calc(36px);

		}
		@include bp(sm) {
			padding-bottom: rem-calc(30px);
		}
	}
}

