
.twocol_content{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(85px 0 55px);
		}
	}

	.pattern{
		background-repeat: no-repeat !important; 
		background-size: cover!important;
		height: 100%;
	}



	.top_txt{		
		margin: 0 auto rem-calc(20px);
		
		h2{
			@include bp(lgmin) {
				margin-bottom: rem-calc(21px);
			}
		}

		.top_icon{
			max-width: rem-calc(204px);
			margin: 0 auto rem-calc(19px);

			@include bp(lg) {
				max-width: rem-calc(190px);
			}
			@include bp(tabletmd) {
				max-width: rem-calc(170px);
			}
			@include bp(tablet) {
				max-width: rem-calc(160px);

			}
			@include bp(xsm) {
				max-width: rem-calc(150px);
			}
		}
	}

	.twocol_listbox{
		position: relative;
		padding: rem-calc(0 4px 0 0);
		margin: rem-calc(41px 0 0); 

		@include bp(tabletmd) {
			padding: rem-calc(0 0 0 0);
		}

		@include bp(tablet) {
			padding: rem-calc(0 0 0 0);
		}

		@include bp(sm) {
			margin: rem-calc(20px 0 0);
			padding: rem-calc(0 0 0 0);
		}

		h3{
			margin-bottom: rem-calc(12px);
			line-height: 1.1;
			@include bp(lgmin) {
				font-size: rem-calc(26px);
			}
		}

		p{
			font-size: rem-calc(18px);
			@include bp(lgmin) {
				line-height: 1.4;
			}
			@include bp(sm) {
				font-size:  rem-calc(16);

			}
			
		}

		ul{
			@extend .bulletlist;
		}
	}

	.twocol_btn{
		margin-top: rem-calc(21px);		
	}
	

}
