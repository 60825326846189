
.columns-with-round-icons-block{	

    .secbgimg.pattern{
    	background-size: auto 85% !important;
    }    
    .round-icons-block{
		position: relative;
		padding: rem-calc(0 0 30 105);
		margin: rem-calc(45px 0 0);

		@include bp(tabletXl) {
			padding: rem-calc(0 0 0 90);
		}

		@include bp(tablet) {
			padding: rem-calc(0 0 0 70);
		}

		@include bp(sm) {
			margin: rem-calc(20px 0 0);
			padding: rem-calc(0 0 0 60);
		}

		.secbgimg.pattern{
			background-repeat: repeat-y !important;
			background-size: 100% !important;
		}

		.round-icon{
			position: absolute;
			left:0;
			top: rem-calc(-15);
            padding: rem-calc(17);
			width: rem-calc(75);
            height: rem-calc(75);
            background: $green_txt;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            right:0;
            text-align: center;
            @include bp(tabletXl) {
                width: rem-calc(60);
                height: rem-calc(60);
                padding: rem-calc(15);
            }
            @include bp(tabletmd) {
                width: rem-calc(50);
                height: rem-calc(50);
                padding: rem-calc(10);
            }
            @include bp(sm) {
            	top: rem-calc(-5);
            }
		}

		h3{
			@include bp(lgmin) {
				font-size: rem-calc(25px);
				margin-bottom: rem-calc(10);
                letter-spacing: rem-calc(0.5);
                line-height: 1.4;
			}
		}

		p{
            font-size:rem-calc(18);
			@include bp(lgmin) {
				line-height: 1.4;
			}
            @include bp(tabletmd) {
                font-size:rem-calc(16);
            }
		}
	}
}
