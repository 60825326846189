
.logos-block{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(75px 0 83px);
		}
	}
    ul{        
        &.logos-listing{               
            display: flex;
            justify-content: center;
            align-items:center;            
            margin:rem-calc(45 0 65);            
            @include bp(tabletmd){
                flex-wrap: wrap;
            }            
            @include bp(sm){
                margin-bottom: rem-calc(40);
            }
            @include bp(xsm){
                text-align: center;
                
            }
            li{
                list-style: none;                  
                padding:rem-calc(0 60);                
                margin-bottom: rem-calc(15);
                @include bp(sm) {
                    width:50%;
                    margin-bottom: rem-calc(50);
                }
                @include bp(xsm) {
                    width:100%;
                    padding:rem-calc(0 15);
                }
                .logo-thumb{
                    width:rem-calc(132);
                    height: rem-calc(90);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: center;

                    @include bp(sm) {
                        margin:0 auto;
                    }
                    @include bp(xsm) {
                       width:rem-calc(150);
                    }

                    img{
                        max-height: 100%;
                    }
                }
                
            }
        }
    }
}
