.team_bio {
	&.innerspacing {
		@include bp(lgmin) {
			padding: rem-calc(58px 0 16px);
		}
	}

	.grid-x {
		justify-content: center;
	}

	.top_txt {
		margin: rem-calc(0 0 32px);
	}

	.secbgimg.pattern {
		background-repeat: no-repeat !important;
		background-size: auto 100% !important;
	}

	.team_person {
		margin-top: rem-calc(23px);
		margin-bottom: rem-calc(26px);

		@include bp(xsm) {
			margin-bottom: rem-calc(20px);
		}

		.teampr_info {
			text-align: center;

			> a {
				display: block;

				&:hover, &:focus {
					opacity: 1;
				}
			}

			.teampr_img {
				max-height: rem-calc(312);
				max-width: rem-calc(312);
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #f6f6f6;
				margin: 0 auto rem-calc(22);
				overflow: hidden;
				position: relative;
				padding-bottom: 81%;

				@include bp(lg) {
					padding-bottom: rem-calc(294);
					max-height: rem-calc(294);
					max-width: rem-calc(294);
				}

				@include bp(tabletmd) {
					padding-bottom: rem-calc(220);
					max-height: rem-calc(220);
					max-width: rem-calc(220);
				}

				@include bp(tablet) {
					padding-bottom: rem-calc(178);
					max-height: rem-calc(178);
					max-width: rem-calc(178);
				}

				@include bp(sm) {
					padding-bottom: rem-calc(294);
					max-height: rem-calc(294);
					max-width: rem-calc(294);
				}

				@include bp(xsm) {
					padding-bottom: rem-calc(220);
					max-height: rem-calc(220);
					max-width: rem-calc(220);
				}

				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					transition: 0.5s;
				}

				> img {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					margin: 0 auto;
				}

				.arrow_btn {
					opacity: 0;
					position: absolute;
					top: 53%;
					transform: translateY(-50%);
					right: 100%;
					transition: 0.3s;
					z-index: 1;
					text-align: left;
					width: 100%;

					.btn {
						background: none;
						text-transform: uppercase;
						border: none;
						color: $white;
						padding: 0;
						text-align: center;
						display: block;

						&:before {
							width: 100%;
						}

						&:after {
							right: 0;
						}
					}
				}

				&:hover {
					&:after {
						width: 100%;
						height: 100%;
						background: rgba($yellow, .7);
					}

					.arrow_btn {
						opacity: 1;
						right: 5%;
						margin: 0 auto;
						max-width: rem-calc(158);

						@include bp(tablet) {
							max-width: rem-calc(100);
						}

						@include bp(sm) {
							max-width: rem-calc(158);
						}
					}
				}
			}

			.round_peopleimg.roundside_txt {
				padding: rem-calc(8px 0 0);

				.round_person {
					height: rem-calc(200px);
					width: rem-calc(200px);
					padding: 0;
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					transition: 0.2s all;
					margin: 0 auto rem-calc(17px);

					@include bp(tablet) {
						height: rem-calc(177px);
						width: rem-calc(177px);
					}

					.round_personimg {
						height: rem-calc(200px);
						width: rem-calc(200px);
						transition: 0.1s all;

						@include bp(tablet) {
							height: rem-calc(177px);
							width: rem-calc(177px);
							transition: 0.1s all;
						}
					}
				}
			}

			h4 {
				margin-bottom: 0;
				color: $darkgrey;

				@include bp(lgmin) {
					font-size: rem-calc(22px);
				}
			}

			.person_post {
				color: $darkgrey;
				@include bp(lgmin) {
					font-size: rem-calc(18);
				}
			}
		}
	}

	&:not(.hardware_bio) {
		.round_person {
			box-shadow: 0 0 1px #cdcdcd;

			&:hover {
				box-shadow: 0 0 0 3px $green-txt;
			}
		}

		.grid-x.column-5 > .cell.large-3 {
			@include bp(lgmin) {
				width: 20%;
			}
		}
	}

	&.hardware_bio {
		.hardwarlist {
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0;
			@include bp(lgmin) {
				margin: rem-calc(0 -15px);
			}

			.hardware_row {
				padding: rem-calc(0 15px);
				width: rem-calc(208px);

				.team_person {
					.pricetag {
						position: relative;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;

						span {
							position: absolute;
							top: rem-calc(-12px);
							z-index: 1;
							color: $white;
							background: #6c6968;
							padding: rem-calc(5px 14px);
							font-size: rem-calc(16px);
							min-width: rem-calc(65px);

							@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
								left: 50%;
								transform: translateX(-50%);
							}
						}
					}

					.teampr_info {
						.teampr_img {
							padding-bottom: 100%;
							margin-bottom: rem-calc(14px);

							> img {
								top: 50%;
								transform: translateY(-50%);
								right: 0;
								margin: 0 auto;
							}
						}

						.hardname {
							text-align: left;
							color: $darkgrey;
							font-size: rem-calc(18px);
							line-height: 1.3;

							@include bp(sm) {
								font-size: rem-calc(16);
							}
						}
					}
				}
			}
		}
	}
}

.teampr_infodetail {
	.round_person {
		box-shadow: 0 0 1px #cdcdcd;
	}
}

.mfp-wrap {
	@include bp(tablet) {
		height: 100vh;
		top: 0 !important;
	}
}


.mfp-content {
	padding: rem-calc(0 20);

	@include bp(tablet) {
		max-height: 100vh;
		padding: 0;
	}

}

.mfp-arrow {
	opacity: 0 !important;
	user-select: none;
	display: none !important;
	cursor: normal !important;
}


.teampr_infodetail {
	background: $white;
	max-width: rem-calc(1072px);
	width: 100%;
	margin: 0 auto;
	padding: 8.8% rem-calc(28) 3%;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;

	@include bp(lgmin) {
		padding: rem-calc(85px 28px 31px);
	}

	@include bp(sm) {
		padding: rem-calc(55 20 20);
	}

	.teampr_infodetailbg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat !important;
		z-index: -1;
		background-size: cover !important;

		@include bp(sm) {
			opacity: 0;
		}
	}

	.teamdetail_left {
		display: inline-block;
		width: 34%;
		height: 34%;
		max-width: rem-calc(312);
		max-height: rem-calc(312);
		border-radius: 50%;
		overflow: hidden;
		margin-left: 4%;

		@include bp(lgmin) {
			margin-left: rem-calc(33);
		}

		@include bp(lg) {
			margin-left: 0;
		}

		@include bp(tablet) {
			margin-left: 0;
		}

		@include bp(sm) {
			width: 100%;
			margin-bottom: rem-calc(20);
			max-width: rem-calc(216);
		}

		.round_peopleimg.roundside_txt {
			.round_person {
				@include bp(lgmin) {
					height: rem-calc(250px);
					width: rem-calc(250px);
				}
				padding: 0;
				display: inline-flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				transition: 0.2s all;
				margin: 0 auto rem-calc(17px);

				&:hover {
					.round_personimg {
						transform: scale(1);

						@include bp(lgmin) {
							height: rem-calc(220px);
							width: rem-calc(220px);
						}
					}
				}

				.round_personimg {
					background-color: $white !important;
					transition: 0.3s all;

					@include bp(lgmin) {
						height: rem-calc(220px);
						width: rem-calc(220px);
					}
				}
			}
		}
	}

	.teamdetail_right {
		display: inline-block;
		width: 63%;
		padding-left: 4.3%;
		padding-top: rem-calc(22px);
		max-width: rem-calc(590);

		@include bp(sm) {
			width: 100%;
		}

		h4 {
			margin-bottom: 2px;
			color: $orange;
			@include bp(lgmin) {
				font-size: rem-calc(22);
			}
		}

		p {
			line-height: 1.4;
			font-size: rem-calc(18px);
			@include bp(sm) {
				font-size: rem-calc(16);
			}

			&.quote {
				font-size: rem-calc(25px);
				font-style: italic;
				line-height: 1.5;

				@include bp(lg) {
					font-size: rem-calc(23);
				}
				@include bp(tabletmd) {
					font-size: rem-calc(21);
				}
				@include bp(tablet) {
					font-size: rem-calc(20);
				}
				@include bp(xsm) {
					font-size: rem-calc(18);
				}
			}
		}

		.person_post {
			margin-bottom: 5.4%;
			color: $darkgrey;
			@include bp(lgmin) {
				font-size: rem-calc(18);
			}
		}
	}

	.mfp-close-btn {
		background: url(../images/icons/popclose.png) center no-repeat;

		.mob-back-close {
			display: none !important;
		}
	}

	.mfp-close {
		font-family: $roboto;
		cursor: pointer;
		font-weight: 100;
		font-size: rem-calc(55);
		color: $darkgrey;
		top: rem-calc(37px) !important;
		right: rem-calc(36px) !important;


		@include bp(sm) {
			font-size: rem-calc(30);
			right: rem-calc(16) !important;
			top: rem-calc(12) !important;
		}

		&:focus, &:hover {
			right: rem-calc(33);
			top: rem-calc(27);

			@include bp(sm) {
				right: rem-calc(16);
				top: rem-calc(12);
			}
		}
	}
}

.navbio {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 5.5%;

	@include bp(lgmin) {
		margin-top: rem-calc(46);
	}

	.arrow_btn {
		max-width: rem-calc(141);

		@include bp(sm) {
			max-width: rem-calc(120);
		}

		.btn {
			font-weight: $normal;
			border: none;
			color: #000;
			text-transform: capitalize;
			background: none;

			&:hover {
				color: $orange;
			}
		}

		&.next {
			margin-right: rem-calc(3px);
		}

		&.prev {
			margin-left: rem-calc(3px);

			.btn {
				text-align: right;
			}
		}
	}
}

.text-404 {
	ul {
		@extend .bulletlist;
	}
}
