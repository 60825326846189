
.ctaicon{
	position: relative;

	&.innerspacing{
		
		@include bp(lgmin) {
			padding: rem-calc(121px 0 122px);
		}
	}

	.top_icon{
		height: rem-calc(143px);
		margin-bottom: rem-calc(46px);

		@include bp(lg) {
			height: rem-calc(130px);
			margin-bottom: rem-calc(20px);
		}
		@include bp(tablet) {
			height: rem-calc(120px);			
		}
		@include bp(sm) {
			height: rem-calc(104px);
		}

		> img{
			max-height: 100%;
		}
	}
	
	&.overflow_icon{
		&.innerspacing{
			@include bp(lgmin) {
				padding-bottom: rem-calc(82px);
			}
		}
		padding-top: rem-calc(136px);

		@include bp(lg) {
			padding-top: rem-calc(110px);
		}
		@include bp(tablet) {
			padding-top: rem-calc(100px);
		}
		@include bp(sm) {
			padding-top: rem-calc(60px);
		}


		
		.top_icon{
			position: absolute;
			top: rem-calc(-110px);
			left: 0;
			right: 0;
			margin: 0 auto;
			height: rem-calc(174px);

			@include bp(lg) {
				height: rem-calc(164px);
				top:  rem-calc(-87px);
			}
			@include bp(tablet) {
				height: rem-calc(154px);
				top:  rem-calc(-81px);
			}
			@include bp(sm) {
				height: rem-calc(104px);
				top:  rem-calc(-57px);
			}

			img{
				max-height: 100%;
			}
		}
	} 

	.ctaico_btn{
		    margin-top: 5.3%;
	}

	&.contact_cta{
		position: relative;
		z-index: 2;

		&.overflow_icon{
			padding-top: rem-calc(149px);

			@include bp(lg) {
				padding-top: rem-calc(110px);
			}
			@include bp(tablet) {
				padding-top: rem-calc(100px);
			}
			@include bp(sm) {
				padding-top: rem-calc(60px);
			}

			.top_icon{
				    top:  rem-calc(-58px);
			}
		}

		.top_txt p{
			line-height: 1.5;
		}

	}

}
