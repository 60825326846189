
.simple_listblock{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(75px 0 83px);
		}
	}

	.grid-container{
		@include bp(lgmin) {
			max-width: rem-calc(900px);
		}
	}


	.pattern{
		background-repeat: no-repeat !important; 
		background-size: 1080px!important; 
		height: 100%;
		top: auto;
		bottom: -240px;
	}

	.top_txt{		
		margin: 0 auto rem-calc(32px);
		
		h2{

			@include bp(lgmin) {
				margin-bottom: rem-calc(21px);
			}
		}

		p {
			font-weight: $light;
			color: $orange;
			letter-spacing: 0.1px;
		}
	}

	.large-6{
		&:nth-child(odd){
			.simple_listbox{
				@include bp(lgmin) {
					padding: rem-calc(3px 0 0 74px);

					.simple_listico{
						left: rem-calc(15);
					}
				}
			}

		}
	}

	.simple_listbox{
		position: relative;
		padding: rem-calc(3px 0 0 108px);
		margin: rem-calc(20px 0 0); 

		@include bp(tabletmd) {
			padding: rem-calc(0 0 0 77px);
		}

		@include bp(tablet) {
			padding: rem-calc(0 0 0 50px);
		}

		@include bp(sm) {
			margin: rem-calc(20px 0 0);
			padding: rem-calc(0 0 0 36px);
		}

		
		.simple_listico{
			position: absolute;
			left: rem-calc(48px);
			top: rem-calc(-5px);
			width: rem-calc(44px);

			@include bp(tabletmd) {
				left: rem-calc(22px);
			}
			@include bp(tablet) {
				width: rem-calc(32px);
				left: rem-calc(11px);
			}
			@include bp(sm) {
				left: rem-calc(0);
			}

		}

		h4{
			margin-bottom: rem-calc(10px);
			@include bp(lgmin) {
				font-size: rem-calc(22px);
			}
		}

		p , h5{
			font-size: rem-calc(18px);
			@include bp(lgmin) {
				line-height: 1.4;
				margin-bottom: rem-calc(20px);
			}
			@include bp(sm) {
				font-size:  rem-calc(16);

			}
			
		}

		ul{
			@extend .bulletlist;
		}
	}

	.simplelist_btn{
		margin-top: rem-calc(23px);
		@include bp(lgmin) {
			margin-top: rem-calc(41px);
		}

	}
	

}
