  
.testimonial{

	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(92px 0 105px);
		}
	}

	.testislide{
		padding: rem-calc(35px 0 0);
	}

	.testislide_item{
		text-align: center;

		p{
			@extend .mdtxt;
			line-height: 1.55;
			margin: rem-calc(0 0 11px 0); 
			@include bp(lgmin) {
				font-size: rem-calc(25px);
				font-style: italic;
			}
		}

		.personname{
			font-weight: bold;
			font-size: rem-calc(17px);


			@include bp(tablet) {
				font-size: rem-calc(15px); 
			}
		}
	}

	.slick-dots{
		position: absolute;
		top: 0;
		bottom: auto;

		li{
			button{
				&:before{
					top: rem-calc(-7px);
					content: "";
					background: #6c6968;
					opacity: 1;
					width: rem-calc(14px);
					height: rem-calc(14px);
					border-radius: 50%;
					line-height:rem-calc( 14px);
					transition:0.5s all;


				}
				&:hover{
					&:before{
						background:$yellow;
					}
				}
			}

			&.slick-active {
				button{
					&:before{
						background: $orange;
					}
				}
			}
		}
	}



	

}
