.banner{
	position: relative;
	&.innerspacing{
		@include bp(lgmin) {
			padding: rem-calc(95px 0 65px); 
		}
	}


	.bnr_innertxt{
		display:flex;
		flex-wrap:wrap;
		align-content:center;
		justify-content:center;
		min-height:rem-calc(467px);
		margin:rem-calc(17px) auto 0;
		max-width: rem-calc(960px);
		position: relative;
		z-index: 1;


			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				/*padding-top:10%;*/
			}


		@include bp(lg) {
			min-height:rem-calc(300px);			
		}

		.tagline{
			width: 100%;
			font-weight: $bold;
			color: $white;
			@include bp(lgmin) {
				font-size: rem-calc(36px);
			}
		}

		h1{
			margin-bottom: 6%;
			width: 100%;
		}


		p{
			@extend .bigtxt;			
			font-weight:$bold;
			width: 100%;

		}

		.bnrbtn{
			margin: rem-calc(10px 0 20px);
			@include bp(lgmin) {
				margin: rem-calc(40px 0 0);
			}

			.btn{
				@include bp(lgmin) {
					padding: rem-calc(14px 23px);
				}
			}

		}
	}

	


	&.inner_bnr{

		&.whitetxt{
			.bnr_innertxt{
				p.tagline{
					color: $white;
				}
			}
		}

		&.innerspacing{
			@include bp(lgmin) {
				padding: rem-calc(72px 0 61px);
			}
		}
		.bnr_innertxt{
			min-height: rem-calc(223px);
			max-width: 100%;

			p.tagline{
				color: $darkgrey;
				margin-bottom: rem-calc(21px);
				font-size: rem-calc(22px);
				font-weight: $bold;
				text-transform: uppercase;
			}


			h1{
				font-weight:$heavy;
				text-transform: none;
				margin-bottom: rem-calc(32px);
				line-height: 1.1;
				width: 100%;
				
				@include bp(lgmin) {
					font-size: rem-calc(40px);
				}

				@include bp(lg) {
					font-size:  rem-calc(30);
					margin-bottom: rem-calc(30px);
				}
				@include bp(tabletmd) {
					font-size:  rem-calc(28);
					line-height: normal;
					margin-bottom: rem-calc(20px);
				}
				@include bp(tablet) {
					font-size:  rem-calc(26);
					margin-bottom: rem-calc(15px);
				}
				@include bp(xsm) {
					font-size:  rem-calc(20);
				}
			}

			p{

				@extend .mdtxt;
				font-weight: 400;
				letter-spacing: 0.7px;
				line-height: 1.3;

				@include bp(lgmin) {
					font-size: rem-calc(25px);
				}
			}

			.bnrbtn{
				@include bp(lgmin) {
					margin: rem-calc(3px 0 0);
				}


			}

		}
	}
}


.small-hero{
	.banner.inner_bnr{
		&.innerspacing{
			padding: rem-calc(66px 0 47px); 

			@include bp(sm) { 
				padding: rem-calc(46px 0 27px);
			}
		}
		.bnr_innertxt{
			margin-top: 0;
			min-height: rem-calc(160px);

			@include bp(sm) { 
				min-height: rem-calc(120px);
			}

			h1{
				margin-bottom: rem-calc(22px);
			}
		}
	}
}





.bgsecimg ,.videobg {
	position: relative;
	z-index: 1;
	overflow: hidden; 
} 

.bgoverlay{
	position:absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	opacity: 0.7;
}

.shape_part{
	position: absolute;
	bottom: 0;
	width: 100%;
	background-size: 100%!important;
    background-repeat: no-repeat !important;
    height: rem-calc(230px);
    background-position: center bottom -2px !important;

     @include bp(xxlmin) {
     	height: rem-calc(255px);
     }
}

.secbgimg{    
	position:absolute;
	z-index: -2;
	width: 100%;
	height: 120%;
	top:0;
	left:0;
	background-repeat: no-repeat !important;
	background-size: cover !important;

	&.pattern{
		background-repeat: repeat !important;
		    background-size: auto !important;
	}
}

.videobg_child {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	display: block;
	overflow: hidden;
	z-index: -2;


	> video {
		position: absolute;
		top: 50%;
		right: 0;
		bottom: 0;
		left: 50%;
		z-index: 0;
		width: auto;
		height: auto;
		min-height: 100%;
		min-width: 100%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
	}
}


.bnr_innertxt{
	.breadcrum{
		width: 100%;
		color: $white;
		margin-bottom: rem-calc(21px);
		letter-spacing: 0.8px;
		text-transform: uppercase;

		span{
			color: $green-txt;
		}
	}
}




.mfp-iframe-scaler .mfp-close:focus, .mfp-iframe-scaler .mfp-close:hover{
	// top: rem-calc(-40px);
	// right: rem-calc(-6px);
	top: rem-calc(-40px);
	right: rem-calc(-6px);
}




