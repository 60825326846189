
.number_list_content_blocks{

&.innerspacing{
    padding-bottom: rem-calc(30px);
}	    
    ol{
        margin:0;
        &.number_cont_listing{
            padding: rem-calc(60 0 0);
            li{
                position: relative;
                counter-increment: section;                
                display: inline-block;
                padding:rem-calc(22 15 50 105);
                @include bp(tabletmd){
                    padding:rem-calc(5 15 50 90);
                }
                @include bp(tabletsm){
                    padding:rem-calc(5 15 50 75);
                }
                @include bp(sm){
                    padding:rem-calc(95 0 20);
                    text-align: center;
                }
                &:before{
                    content: counter(section);
                    color: $black;
                    background:$yellow;
                    font-weight:$bold;
                    position: absolute;
                    left:0;     
                    font-size:rem-calc(24);
                    font-family:$titilliumWeb;
                    width:rem-calc(75);
                    height:rem-calc(75);
                    line-height:rem-calc(75);
                    text-align: center;
                    -webkit-border-radius:50%;
                    -moz-border-radius:50%;
                    border-radius:50%;
                    top:0;
                    @include bp(tabletmd){
                        width:rem-calc(55);
                        height:rem-calc(55);
                        line-height:rem-calc(55);
                    }
                     @include bp(sm){
                        width:rem-calc(70);
                        height:rem-calc(70);
                        line-height:rem-calc(70);
                        left: 0;
                        right: 0;                        
                        margin:rem-calc(0) auto;
                        
                    }
                }
                h4{
                    margin-bottom: rem-calc(10);
                    font-size:rem-calc(24);
                    letter-spacing: rem-calc(1);
                    @include bp(tabletmd){
                        font-size:rem-calc(20);
                    }
                }
                p{
                    font-size:rem-calc(18);
                    line-height: 1.45;
                }
            }
        }
    }
}
