
.page_list_content_blocks{	    
    .page_list_cont_listing{
        .page_listing-block{
            display:flex;
            justify-content: space-between;
            border-bottom:1px solid #738592;
            padding:rem-calc(0 0 20 22); 
            margin-top: rem-calc(38);
            @include bp(sm){
                display: block;
                padding:rem-calc(0 0 20 0);
            }
            &:first-child{
                margin-top: 0;
            }
            &:last-child{
                 border-bottom:none;
            }
            .page_listing_img_thumb{
                width:12%; 
                @include bp(tabletmd){
                    width:15%;
                }
                @include bp(tabletsm){
                    width:25%;
                }
                @include bp(sm){
                   margin:0 auto rem-calc(25);
                    width:40%;
                }
            }
            .page_listing-cont{
                width:86%;
                padding-left:rem-calc(20);                
                h4{
                    margin-bottom:rem-calc(10);
                    font-size:rem-calc(24);
                    letter-spacing: rem-calc(1);
                    @include bp(tabletmd){
                        font-size:rem-calc(20);
                    }
                }
                p{
                    margin-bottom: rem-calc(18);
                    line-height: 1.4;
                }
                ul{
                    margin-left:0;
                    li{
                        position: relative;
                        padding: rem-calc(0 4 0 15);
                        line-height: 1.35;
                        font-family:$titilliumWeb;
                        list-style: none;
                        
                        &:before{
                            content: "";
                            position: absolute;
                            top: 0.25rem;
                            left: -3px;
                            background: url(../images/icons/orangelist_tick.png;) center no-repeat;
                            width: 0.9375rem;
                            height: 0.9375rem;
                            background-size: 100%;
                        }
                    }
                }
                
            }            
        }   
    }
}
