  
.instafeed {

	&.innerspacing{	
	padding-bottom: rem-calc(25px);	
		@include bp(lgmin) {
			padding: rem-calc(48px 0 25px);
		}
	}

	.top_txt{
		
		h2{
			color: $white;
			margin-bottom: rem-calc(10px);

			@include bp(lgmin) {
				margin: rem-calc(0 0 32px);
			}
		}
	}

	.instafeedinner{
		display: flex;
		flex-wrap:wrap;
		justify-content: center;
		@include bp(lgmin) {
			margin:rem-calc( 0 -11px);
		}
		.item{
			width: 20%;
			padding:rem-calc(11px);
			@include bp(tablet) {
				width: 33.33%;
			}
			@include bp(xsm) {
				width: 50%;
				padding:rem-calc(5px);
			}
			.instaimg{
				padding: 0 0 100%;
				    border: 1px solid $darkgrey;
			}
		}
	}

	.insta_btn{
		margin: rem-calc(24px 0 0);

		.btn{
			margin: rem-calc(9px 4px);
			@include bp(lgmin) {
				padding: rem-calc(12px 23px);
				min-width: rem-calc(184px);
			}
		}
	}

}
