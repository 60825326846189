.wp-caption > figcaption {
  max-width: 100%;
  font-size: 0.8rem;
  color: #999;
  padding: 0.25rem 0;
}

p.wp-caption-text{
  font-size:90%;
  color: #666;
  padding:rem-calc(10) 0;
}

.alignleft {
  float: left;
  padding-right: 1rem;
  margin: 0;
}

.alignright {
  float: right;
  padding-left: 1rem;
  margin: 0;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.gallery {
  @include xy-grid;
  @include xy-gutters($negative: true);
  @for $i from 1 through 9 {
    &.gallery-columns-#{$i} {
      @include xy-grid-layout(2, '.gallery-item', true);
    }
    @include breakpoint(medium){
      &.gallery-columns-#{$i} {
        @include xy-grid-layout(4, '.gallery-item', true, (small: 30px));
      }
    }
    @include breakpoint(large) {
      &.gallery-columns-#{$i} {
        @include xy-grid-layout($i, '.gallery-item', true, (small: 30px));
      }
    }
  }
  .gallery-icon > a {
    @include thumbnail;
    @include thumbnail-link;
  }
  .gallery-icon > img {
    @include thumbnail;
  }
}
