// Navigation breakpoints
//.mobile-menu,
//.site-title-bar {
//  @include hide-for(medium);
//}
////
//.desktop-menu,
//.site-navigation .top-bar-left {
//  @include show-for(medium);
//}
//
//// Site title
//.site-desktop-title a {
//  font-weight: bold;
//}
//
//// WP post navigation
//.post-navigation {
//  @include clearfix;
//}
//
//.nav-previous {
//  float:left;
//}
//
//.nav-next {
//  float:right;
//}


/*f-dev*/


.site-header {
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: slide-down 0.8s;
        animation-direction: normal;
        animation-duration: 0.8s;
        transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        /*background: #fff;
        border-bottom: 1px solid rgba($darkgreen , .2); */
        z-index: 6;
        .logo_part {
            padding:rem-calc(11px 0);
        }
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}


.site-header{
    .top-bar{
        background: $white !important;
        color: $blacktop;
        height: inherit;
        position: relative;
    }
    .grid-container{
        width:100%;
        @include bp(xlmin){
            max-width:1366px;
            padding:rem-calc(0 5);
        }
    }
    .top-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @include bp(tabletmd){

        }
        .top-bar-left{
            width:22%;
            display: inline-block;
            padding-left:rem-calc(70);

            .site-desktop-title{
                img{
                    max-width: rem-calc(211);

                    @include bp(lg){
                        max-width: rem-calc(150);
                    }
                }
            }
            @include bp(xl){
                padding-left:0;
            }
            .mega-menu-item{
                margin-right: 0;
            }
            @include bp(lg){
                padding-left:0;
            }
            @include bp(tabletmd){
                width:auto;
            }
            .site-desktop-title{
                @include bp(sm){
                    max-width:80%;
                }
            }
        }
        .top-bar-right{
            width:78%;
            display: inline-block;
            position: static;
            @include bp(tabletmd){
                width:auto;
            }
            #mega-menu-wrap-top-bar-r{
                background: transparent;
                position: static;
                .mega-menu-toggle{
                    .mega-toggle-block{
                        margin-right:0;

                        @include bp(tabletmd) {
                            margin-right: -5px;
                        }
                    }
                    .mega-toggle-block-1{
                        .mega-toggle-label{
                            font-size:0;
                        }
                        &:after{
                            font-size:rem-calc(40) !important;
                            color: $black !important;
                        }
                    }
                    background: transparent !important;
                    @include bp(tabletmd){
                        width: auto;
                        display: inline-block;
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                #mega-menu-top-bar-r{
                    text-align: right;
                    position: static;
                    @include bp(tabletmd){
                        position: absolute;
                        top:100%;
                        z-index:9;
                        left:50%;
                        right:0;
                        width:50%;
                        height:100vh;
                        overflow: auto;
                        @include transition(0.5s, all, ease);
                        visibility: hidden;
                        opacity: 0;
                    }
                    li{
                        &.mega-menu-item{
                            text-align: center !important;
                            margin-right: 0;
                            // margin-right:rem-calc(20);
                            @include bp(xl){
                                // margin-right:rem-calc(15);
                            }
                            @include bp(lg){
                                // margin-right:rem-calc(10);
                            }
                            @include bp(tabletmd){
                                // margin-right:0;
                                padding:rem-calc(5 0);
                            }
                            a{
                                font-size:rem-calc(16);
                                font-family:$roboto;
                                font-weight:$bold;
                                position: relative;
                                padding:0;
                                @include bp(xl){
                                    font-size:rem-calc(14);
                                }
                                @include bp(lg){
                                    font-size:rem-calc(13);
                                }
                                @include bp(tabletXl){
                                    font-size:rem-calc(13);
                                }
                                @include bp(tabletmd) {
                                    font-size:rem-calc(16);
                                    padding:rem-calc(0 15);
                                }
                                
                                &.mega-menu-link{
                                    font-family: $roboto;
                                    color: $blacktop;
                                    font-weight: $normal;
                                    // min-width: 6.5em;
                                    margin: 0 10px;
                                    text-align: center;
                                    @include bp(tabletmd){
                                        font-size: rem-calc(25);
                                    }
                                    span{
                                        &.mega-indicator{
                                            display: none !important;
                                            // &:after{
                                            //     font-size:rem-calc(26);
                                            // }
                                            // @include bp(tabletmd) {
                                            //     display:block;
                                            // }
                                        }

                                    }
                                    // &:after{
                                    //     display:block !important;
                                    //     content:"";
                                    //     position: absolute !important;
                                    //     background: $yellow !important;
                                    //     width:0%;
                                    //     height:rem-calc(2);
                                    //     bottom:0;
                                    //     visibility: hidden !important;
                                    //     opacity: 0 !important;
                                    //     left:0;
                                    //     right:0;
                                    //     margin:0;
                                    //     @include transition(0.3s, all, ease);
                                    //     @include bp(tabletmd){
                                    //         display:none !important;
                                    //     }
                                    // }
                                    &:hover {
                                        font-weight: $bold;
                                        border-bottom: 2px solid $yellow !important;
                                        padding-bottom: 2px;

                                        @include bp(tabletmd){
                                            display: inline-table !important;
                                            margin: 0;
                                            padding: 0;
                                        }
                                        // &:after{
                                            
                                        //     @include bp(tabletmd){
                                        //         display:block !important;
                                        //         content:"";
                                        //         position: absolute !important;
                                        //         background: $yellow !important;
                                                
                                        //         width: 10%;
                                        //         opacity: 1 !important;
                                        //         visibility: visible !important;

                                        //         height:rem-calc(2);
                                        //         bottom:0;
                                        //         left:0;
                                        //         right:0;
                                        //         margin:auto;
                                        //         @include transition(0.3s, all, ease);
                                        //     }
                                            
                                        //     display:block !important;
                                        //     content:"";
                                        //     position: absolute !important;
                                        //     background: $yellow !important;
                                            
                                        //     width: 50%;
                                        //     opacity: 1 !important;
                                        //     visibility: visible !important;

                                        //     height:rem-calc(2);
                                        //     bottom:0;
                                        //     left:0;
                                        //     right:0;
                                        //     margin:auto;
                                        //     @include transition(0.3s, all, ease);
                                            
                                            
                                        // }
                                    }
                                }
                                // &:hover{
                                //     font-family: $roboto;
                                //     font-weight: $bold;
                                //     &:after{
                                //         width: 100%;
                                //         opacity: 1 !important;
                                //         visibility: visible !important;
                                //     }
                                // }   
                            }

                            ul{
                                &.mega-sub-menu{
                                    z-index: 999;
                                    background:$white;
                                    padding: rem-calc(15 0 0) !important;
                                    position: absolute;
                                    width: 160px;
                                    // min-width: 200px;
                                    left: -40px;
                                    right: 0;
                                    top: 100%;
                                    transform: translate(0, 0);
                                    @include bp(tabletmd){
                                        position: relative;
                                        top:0;
                                    }
                                    @include bp(tabletmd){
                                        width: 100%;
                                        left: 0;
                                    }
                                    .wiget-wrapper{
                                        width:100%;
                                        max-width:rem-calc(740);
                                        padding-left:rem-calc(135);
                                        @include bp(lg){
                                            padding-left:rem-calc(50);
                                        }
                                        @include bp(tabletmd){
                                            padding:rem-calc(0 15);
                                        }
                                        @include bp(tabletsm){
                                            padding:rem-calc(0 15);
                                        }
                                        p{
                                            color:$white;
                                            margin-bottom:0;
                                            padding-bottom: rem-calc(15);
                                            font-size:rem-calc(16);
                                            @include bp(tabletsm){
                                                font-size:rem-calc(14);
                                            }
                                        }
                                        h4{
                                            color:$white;
                                            font-size:rem-calc(22);
                                            padding-bottom:rem-calc(10);
                                            @include bp(tabletmd){
                                                font-size:rem-calc(20);
                                            }
                                            @include bp(tabletsm){
                                                font-size:rem-calc(18);
                                            }
                                        }
                                        .btn-wrapper{
                                            display:flex;
                                            flex-wrap:wrap;
                                            @include bp(xsm){
                                                display:block;
                                            }
                                            .btn{
                                                margin-right:rem-calc(15);
                                                padding:rem-calc(10 18);
                                                margin-bottom:rem-calc(15);
                                                @include bp(tabletsm){
                                                    font-size:rem-calc(16);
                                                    padding:rem-calc(8 8);
                                                    margin-right:rem-calc(10);
                                                }
                                                @include bp(lg){
                                                    font-size:rem-calc(14);
                                                }
                                                @include bp(sm){
                                                    font-size:rem-calc(12);
                                                }
                                                &.whiteghostbtn{
                                                    &:hover{
                                                        background: none !important;
                                                        color: $yellow;
                                                        border: 1px solid $yellow;
                                                    }
                                                }
                                                &:last-child{
                                                    margin-right:0;
                                                }
                                                @include bp(xsm){
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                    li {
                                        a {
                                            color: $blacktop;
                                            font-weight: $normal;
                                            font-family: $roboto;
                                            &.mega-menu-link {
                                                background: transparent !important;
                                                font-size: rem-calc(12);
                                                padding: 6px 3px !important;
                                                line-height: 15px !important;
                                                @include bp(tabletmd){
                                                    font-size: rem-calc(16);
                                                }
                                                &:hover {
                                                    color: $blacktop;
                                                    font-weight: $bold;
                                                    background: $lightgrey !important;
                                                    margin: 0 !important;
                                                    border-bottom: 2px transparent !important;
                                                    @include bp(tabletmd){
                                                        display: block !important;
                                                    }
                                                }
                                                &:after{
                                                    content:"";
                                                    background: transparent !important;
                                                }
                                            }
                                        }
                                    }
                                    ul{
                                        &.menu-listing{
                                            display:flex;
                                            margin:rem-calc(35 50 0);
                                            flex-wrap:wrap;
                                            background:transparent;
                                            @include bp(lg){
                                                margin:rem-calc(35 15 0);
                                            }
                                            li{
                                                width:calc(25% - 30px);
                                                margin:rem-calc(0 15 42);
                                                display:flex;
                                                flex-wrap:wrap;
                                                align-items: center;
                                                @include bp(tabletmd){
                                                    width:calc(33.33% - 30px);
                                                }
                                                @include bp(tabletsm){
                                                    width:calc(50% - 30px);
                                                }
                                                @include bp(sm){
                                                    width:100%;
                                                }
                                                .img-thumb{
                                                    height:rem-calc(55);
                                                    width:rem-calc(55);
                                                    img{
                                                        display: inline-block;
                                                        max-width:100%;
                                                        max-height:100%;
                                                    }
                                                    @include bp(tabletsm){
                                                        height:rem-calc(50);
                                                        width:rem-calc(50);
                                                    }
                                                    @include bp(sm){
                                                        height:rem-calc(40);
                                                        width:rem-calc(40);
                                                    }
                                                }
                                                .desc{
                                                    width:70%;
                                                    padding-left:rem-calc(20);
                                                    a{
                                                        color:$white;
                                                        font-weight:$bold;
                                                        font-size:rem-calc(18);
                                                        font-family:$roboto;
                                                        opacity: 1 !important;
                                                        &:hover{
                                                            color:$yellow !important;
                                                        }
                                                        @include bp(lg){
                                                            padding:0;
                                                            font-size:rem-calc(16);
                                                        }
                                                        @include bp(tabletXl){
                                                            font-size:rem-calc(14);
                                                        }
                                                        @include bp(tabletmd){
                                                            padding:0;
                                                        }
                                                        @include bp(tabletsm){
                                                            padding:0;
                                                            font-size:rem-calc(16);
                                                        }

                                                        span{
                                                            display: block;
                                                            font-weight: $normal;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.mega-current-menu-item, &.mega-current-menu-ancestor, &.mega-current-page-ancestor{
                            a{
                                &.mega-menu-link{
                                    background: transparent !important;
                                    &:after{
                                        opacity: 1 !important;
                                        visibility: visible !important;
                                        @include bp(tabletmd){
                                            display:none !important;
                                            opacity: 0 !important;
                                            visibility: hidden !important;
                                        }
                                    }
                                }
                            }
                        }
                        &.mega-current-menu-item.button{
                            a{
                                &.mega-menu-link{
                                    &:after{
                                        opacity: 0 !important;
                                    }
                                }
                            }
                        }
                        &:last-child{
                            &.mega-menu-item{
                                margin-right:0;
                            }
                        }
                        &.button{
                            margin-right:0 !important;
                            a{
                                &.mega-menu-link{
                                    font-weight: $mediumdark;
                                    background:$orange;
                                    border:1px solid $orange;
                                    padding:rem-calc(5 15);
                                    -webkit-border-radius:rem-calc(12);
                                    -moz-border-radius:rem-calc(12);
                                    border-radius:rem-calc(12);
                                    height: auto !important;
                                    display: inline-block !important;
                                    line-height: inherit !important;
                                    text-transform: uppercase;
                                    @include transition(0.5s, all, ease);
                                    &:hover{
                                        border:1px solid $yellow;
                                        background:$yellow!important;
                                        &:after{
                                            opacity: 0 !important;
                                            visibility: hidden !important;
                                        }
                                    }
                                }
                            }
                            @include bp(tabletmd){
                                margin-left:rem-calc(15);
                            }
                        }
                        &.mega-toggle-on{
                            a{
                                &.mega-menu-link{
                                    background: transparent !important;
                                    font-weight: $bold;
                                    border-bottom: 2px solid $yellow !important;
                                    padding-bottom: 2px;

                                    @include bp(tabletmd){
                                        display: inline-table !important;
                                        padding: 0;
                                        margin: 0;
                                    }
                                    // &:after{
                                    //     display:block !important;
                                    //     content:"";
                                    //     position: absolute !important;
                                    //     background: $yellow !important;
                                        
                                    //     width: 50%;
                                    //     opacity: 1 !important;
                                    //     visibility: visible !important;

                                    //     height:rem-calc(2);
                                    //     bottom:0;
                                    //     left:0;
                                    //     right:0;
                                    //     margin:auto;
                                    //     @include transition(0.3s, all, ease);

                                    //     @include bp(tabletmd){
                                    //         display:block !important;
                                    //         content:"";
                                    //         position: absolute !important;
                                    //         background: $yellow !important;
                                            
                                    //         width: 10%;
                                    //         opacity: 1 !important;
                                    //         visibility: visible !important;

                                    //         height:rem-calc(2);
                                    //         bottom:0;
                                    //         left:0;
                                    //         right:0;
                                    //         margin:auto;
                                    //         @include transition(0.3s, all, ease);
                                    //     }
                                        
                                    // }
                                }
                            }
                            ul.mega-sub-menu {
                                li.mega-menu-item {
                                    a {
                                        &.mega-menu-link {
                                            border-bottom: 2px solid transparent !important;
                                            padding: auto !important;
                                            margin: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
body{
    &.mega-menu-top-bar-r-mobile-open{
        .site-header{
            .top-wrapper{
                .top-bar-right{
                    #mega-menu-wrap-top-bar-r {
                        #mega-menu-top-bar-r{
                            @include bp(tabletmd){
                                opacity:1;
                                visibility:visible;
                            }
                        }
                    }
                }
            }
        }
    }
}
.site-header .top-wrapper{
    .top-bar-left{
        width: 300px;
        display: flex;
        align-items: center;
        .top-login-btn{
            padding-right: rem-calc(15);

            @include bp(lg){
                padding-right: rem-calc(10);
            }

            .btn{
                background: $yellow;
                border-color: $yellow;

                &:hover{
                    background: $orange;
                    border-color: $orange;
                }

                @include bp(lg){  padding: 0.3125rem 0.9375rem;     font-size: 0.875rem;}
            }

            @include bp(tabletmd){
                display: none;
            }
        }



    }
    .top-bar-right{
        width: calc(100% - 300px);

    }

    @include bp(xl){
        .top-bar-left{
            width: rem-calc(340);
        }
        .top-bar-right{
            width: calc(100% - 340px);
        }
    }
    @include bp(lg){
        .top-bar-left{
            width: rem-calc(230);
        }
        .top-bar-right{
            width: calc(100% - 230px);
        }
    }
    @include bp(tabletmd){
        .top-bar-left{
            width: rem-calc(300);
        }
        .top-bar-right{
            width: calc(100% - 300px);
        }
    }
}

.site-header .top-wrapper .top-bar-right #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r li.button.yellowbtn{
    display: inline-table;
    margin-left: 0 !important;
    .mega-menu-link{
        @extend .btn;
        border-radius: 30px !important;
        font-size: rem-calc(13) !important;
        padding: rem-calc(7 24) !important;
        background: $yellow !important;
        border: solid 2px $yellow !important;
        text-transform: none !important;
        &:hover, &:focus {
            opacity: 1;
            border: 2px solid $yellow !important;
            color: $blacktop !important;
            background: $white !important;
            padding: rem-calc(7 24) !important;
            font-size: rem-calc(13) !important;

            @include bp(lg) {
                padding: rem-calc(7 24) !important;
                font-size: rem-calc(13) !important;
            }
        
            @include bp(tablet) {
                padding: rem-calc(7 24) !important;
                font-size: rem-calc(13) !important;
        
            }
            @include bp(xsm) {
                padding: rem-calc(9 38) !important;
                font-size: rem-calc(13);
            }
        }

        // background: $yellow !important;
        // border-color: $yellow !important;

        // &:hover{
        //     background: $orange !important;
        //     border-color: $orange !important;
        // }
    }

    @include bp(tabletmd){
        display: block;
    }

}

.site-header .top-wrapper .top-bar-right #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r li.button.greenbtn{
    display: inline-table;
    margin-left: 0 !important;
    .mega-menu-link{
        @extend .btn;
        border-radius: 30px !important;
        font-size: rem-calc(13) !important;
        padding: rem-calc(7 24) !important;
        background: $green !important;
        border: 2px solid $green !important;
        text-transform: none !important;
        &:hover, &:focus {
            opacity: 1;
            border: 2px solid $green !important;
            color: $blacktop !important;
            background: $white !important;
            padding: rem-calc(7 24) !important;
            font-size: rem-calc(13) !important;

            @include bp(lg) {
                padding: rem-calc(7 24) !important;
                font-size: rem-calc(13) !important;
            }
        
            @include bp(tablet) {
                padding: rem-calc(7 24) !important;
                font-size: rem-calc(13) !important;
        
            }
            @include bp(xsm) {
                padding: rem-calc(9 38) !important;
                font-size: rem-calc(13);
            }
        }

        // background: $yellow !important;
        // border-color: $yellow !important;

        // &:hover{
        //     background: $orange !important;
        //     border-color: $orange !important;
        // }
    }

    @include bp(tabletmd){
        display: block;
    }

}



