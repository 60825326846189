
.order-list-block{ 
    &.innerspacing{
		@include bp(lgmin) {
			padding:rem-calc(88px 0 76px);
		}        
	}

    .secbgimg.pattern{
        background-repeat: repeat-y !important;
        background-size: 100% !important;
    }
    .top_txt{
        padding-left:rem-calc(105); 
        @include bp(tabletXl){
            padding-left:rem-calc(50);
        }
        @include bp(tabletmd){
            padding-left:rem-calc(15);
        }
        @include bp(sm){
            padding-left:0;
        }
    }
    ol{
        margin:0;
        &.order-listing{
            padding: rem-calc(48 0 0 105);
            @include bp(tabletXl) {
                padding: rem-calc(48 0 0 50);
            }
            @include bp(tabletmd) {
                padding: rem-calc(48 0 0 15);
            }
            @include bp(sm) {
                padding: rem-calc(48 0 0 0);
            }
            > li{
                position: relative;
                counter-increment: section;                
                display: inline-block;
                padding:rem-calc(22px 15px 65px 105px);
                max-width:72%;              
                @include bp(tabletmd) {
                    padding:rem-calc(12 15 50 90);
                }
                @include bp(tabletsm) {
                    padding:rem-calc(12 15 50 75);
                }        
                @include bp(sm) {
                    max-width:100%;
                    padding:rem-calc(12px 15px 35px 55px);
                }
                &:before{
                    content: counter(section);
                    color: $white;
                    background:$green-txt;
                    font-weight:$bold;
                    position: absolute;
                    left:0;     
                    font-size:rem-calc(28);
                    font-family:$titilliumWeb;
                    width:rem-calc(75);
                    height:rem-calc(75);
                    line-height:rem-calc(75);
                    text-align: center;
                    -webkit-border-radius:50%;
                    -moz-border-radius:50%;
                    border-radius:50%;
                    top:rem-calc(-5);
                    @include bp(tabletmd) {
                        width:rem-calc(55);
                        height:rem-calc(55);
                        line-height:rem-calc(55);
                    }
                    @include bp(sm) {
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        font-size:rem-calc(20px);
                        top:rem-calc(0);
                    }
                }    
                &:nth-child(even){
                    margin-left:28%;
                    @include bp(sm) {
                        margin-left: 0;
                    }
                }
                &:last-child{
                    padding-bottom: 0;
                }
                h4{
                    margin-bottom: rem-calc(10);
                    font-size:rem-calc(24);                    
                    @include bp(tabletmd){
                        font-size:rem-calc(20);
                    }
                }
                p{
                    font-size:rem-calc(24);
                    line-height: 1.35;
                    color:$yellow;
                    margin-bottom: rem-calc(5);
                    em{
                        font-weight: $normal;
                    }
                    @include bp(tabletXl) {
                        font-size:rem-calc(20);
                    }
                    @include bp(tabletsm) {
                        font-size:rem-calc(18);
                    }                         
                }
                ul{
                    margin:11px 0 0 2px;
                    li{
                        display: block;
                        position: relative;
                        padding: rem-calc(0 4 0 15);
                        line-height: 1.4;
                        font-family:$titilliumWeb;
                        list-style: none;
                        counter-increment: none; 
                        &:before{
                            content: "";
                            position: absolute;
                            counter-increment:none; 
                            top: 0.25rem;
                            left: -3px;
                            background: url(../images/icons/orangelist_tick.png;) center no-repeat;
                            width: 0.9375rem;
                            height: 0.9375rem;
                            background-size: 100%;
                        }
                    }
                }
            }
        }
    }

    .orderlist_btn{
        margin-top: rem-calc(30px);
    }
}
