.footer-container {
  @include xy-grid-container;
  border-top: 1px solid $medium-gray;
  margin-top: rem-calc(60);
}

.footer-grid {
  @include xy-grid;
  padding: rem-calc(30) 0;

  section {
    @include xy-cell();
    @include breakpoint(large) {
      @include xy-cell(auto);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}



/*fdev*/

.footer_part{
    position: relative;
    /*z-index: -1;*/
    .contact_art{
        position: relative;
        z-index: 3;
        padding-bottom: 0;
        margin: rem-calc(0 0 -23px);
        overflow: visible;
        @include bp(lgmin) {
            padding: rem-calc(121px 0 0px); 
        }
        @include bp(lg) {
            margin: rem-calc(0 0 -43px);
        }


        .grid-container{
            max-width: rem-calc(1100px);
        }

        .contact_artrow{
            .cell{
                display: flex;
                flex-wrap:wrap;
                justify-content: flex-end;
            }
        }

        .contact_artbg{
            position: absolute;
            bottom: rem-calc(-84px);
            left: 0;
            right: 0;
            margin: 0 auto; 
            background-size: auto!important;
            background-position: center bottom!important;
            width: 100%;
            height: 100%;
            z-index: -1;

            @include bp(lg) {
                bottom: rem-calc(-54px);
            }

            @include bp(tabletmd) {
                background-size: cover !important;
                bottom: rem-calc(-34px);
            }

            @include bp(sm) {
                bottom: rem-calc(-20px);
            }

            @include bp(xsm) {
                bottom: rem-calc(-5px);
            }

        }

        .contact_artbox{
            background: rgba($darkgrey , 0.92);
            color: $white;
            padding:rem-calc(56px 20px 51px 76px);
            text-align: left;
            width: 50.2%;
            margin-bottom: 26%;

            @include bp(lg) {
                padding: rem-calc(46px 20px 41px 56px);
            }
            @include bp(tabletmd) {
                padding: rem-calc(26px 20px 21px 36px);
            }

             @include bp(sm) {
                width: 100%;
                padding: rem-calc(20px);
             }

            h2{
                @include bp(lgmin) {
                    margin: rem-calc(0 0 9px 0); 
                }
            }
            p{
                letter-spacing: 1px;
            }

            .contact_artbtn{
                margin: rem-calc(31px 0 0);

                .btn{
                    @include bp(lgmin) {
                        padding: rem-calc(10px 17px);
                    }
                }
            }
        }
    }

    .footer_art{
        position: relative;
        z-index: 2;

        .secbgimg.pattern{
            z-index: 0;
            top: auto;
            bottom: 0;
            background-size: 100% !important;
            opacity: 0.05;
            height: 370%;
            background-position: 0 90px !important;
            background-position:bottom center !important;
            background-position: bottom calc(-240px + 100%) center !important;
            background-repeat: no-repeat !important;
            @include bp(lg) {
                background-position: bottom calc(-240px + 40%) center !important;
            }
        }
    }  

    .footer_art .footer_artinner{
        position: relative;       
        padding: rem-calc(133px 0 0);
        overflow: hidden;
        

        @include bp(xxlmin) {
            padding: rem-calc(180px 0 0);
        }

        @include bp(lg) {
            padding: rem-calc(103px 0 0);
        }

        @include bp(sm) {
            padding: rem-calc(73px 0 0);
        }

        &:before {
            position: absolute;
            background: #3b3637;
            display: block;
            height: calc(100% - 198px);
            width: 100%;
            bottom: 0;
            /*content: "";*/
            z-index: -1;
            @include bp(xxlmin) {
                height:  calc(100% - 234px);
            }

            @include bp(sm) {
                height: calc(100% - 161px);
            }

            @include bp(xsm) {
                content: "";
                height: calc(100% - 81px);
            }
        }

        .shape_footer{
            position: absolute;
            top: 0;
            left: rem-calc(-2px);
            right: 0;
            margin: 0 auto;
            background-size: 100% 100%!important;
            background-position: top center !important; 
            width: 101%;
            height: rem-calc(316px);
            z-index: -1;

            background-size: 100% auto!important;
            @include bp(xxlmin) {
                /*height:  calc(100% - 163px);
                background: url('../images/footershape2.svg')!important;*/
            }
        }

        .footer_artinfo{
            padding: rem-calc(30px 0);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;

            @include bp(tablet) {
                padding: rem-calc(30px 0 0);
                padding: 0;
            }

            .mjftr_logo{
                width: rem-calc(211px);
                position: relative;
                z-index: 1;

                @include bp(tablet) {
                    margin: 0 auto;                    
                    text-align: center;
                }

                > a{
                    &:hover{
                        opacity: 1;
                    }
                }
            }

            .ftr_social{
                position: absolute;
                left: rem-calc(14px);
                right: 0;
                margin: auto;
                text-align: center;

                @include bp(tabletmd) {
                    left: rem-calc(4px);
                }
                
                @include bp(tablet) {
                    position: static;
                    width: 100%;
                    margin:rem-calc( 0 0 0 14px);
                }

                @include bp(xsm) {
                    margin:rem-calc( 0 0 0 0);
                }

                .fa{
                    font-size: rem-calc(21px);
                    display: inline-block;
                    padding: rem-calc(11px 7px 0);
                }
                ul{
                    margin-left: 0;
                    @include bp(tablet) {
                        margin-bottom: rem-calc(5px);
                    }
                    li{
                        display:inline-block;

                        a{
                            color:$white;
                        }
                    }
                }
            }

            .footer_copy{
                color: $white;
                font-size: rem-calc(16px);

                @include bp(tablet) {
                    width: 100%;
                    text-align: center;
                }

                a{
                    color: $white;
                    position: relative;
                    padding: rem-calc(0 0 0 9px);
                    margin: rem-calc(9px 0 0 7px);
                    display: inline-block;

                    &:before{
                        content: "";
                        display: inline-block;
                        position: absolute;                        
                        background:$white;
                        height: rem-calc(15px);
                        width: 1px;                        
                        left: 0;
                        top: rem-calc(6px);

                        @include bp(tablet) { 
                            top: rem-calc(2px); 
                        }
                    }
                }
            }


        }
    }

    .footer_mj{
        background: $blacktop;
        position: relative;
        z-index: 2;
        padding: rem-calc(68 0);

        .footer_artinfo{
            // padding: rem-calc(68px 0);
            // padding: rem-calc(40 0 0 0);
            position: relative;

            .mjftr_logo{
                width: rem-calc(180px);
                margin-bottom: rem-calc(12px);
                > a{
                    &:hover{
                        opacity: 1;
                    }
                }
            }

            .footer_copy{
                font-family: 'Roboto';
                color: $white;
                font-size: rem-calc(14px);
                padding: 0 5px;

                a{
                    color: $white;
                    position: relative;
                    padding: rem-calc(0 0 0 9px);
                    margin: rem-calc(0 0 0 7px);
                    display: inline-block;

                    &:before{
                        content: "";
                        display: inline-block;
                        position: absolute;                        
                        background:$white;
                        height: rem-calc(15px);
                        width: 1px;                        
                        left: 0;
                        top: rem-calc(3px);

                        @include bp(tablet) { 
                            top: rem-calc(2px); 
                        }
                    }
                }
            }

            .ftr_social{
                .fa{
                    font-size: rem-calc(21px);
                    display: inline-block;
                    padding: rem-calc(8px 7px 0);
                    
                    @include bp(sm) {
                        font-size: rem-calc(32px);
                    }
                }

                ul{
                    margin-left: 0;
                    @include bp(tablet) {
                        margin-bottom: rem-calc(5px);
                    }
                    li{
                        display:inline-block;

                        a{
                            color:$white;
                            &:hover{
                                color:$yellow;
                            }
                        }
                    }
                }
            }

        }

        .footer_mail{
            p{
                font-family: 'Roboto';
                color: $white;
                font-weight: 400;
                font-size: rem-calc(14px);
            }
            .hbspt-form{ 
                .hs-form{
                    padding: 0;
                    .hs_submit{
                        .actions{
                            &:hover::before{
                                background-color: $white;
                                color: $yellow;
                            }
                            &::before{
                                content: '\f178';
                                font-family: 'FontAwesome';
                                font-size: rem-calc(20px);
                                color: $blacktop;
                                background-color: $yellow;
                                height: rem-calc(43px);
                                padding: rem-calc(10px 20px);
                                border: 1px solid $yellow !important;
                                border-radius: rem-calc(30px);
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                position: absolute;
                                z-index: 10;
                                transition:0.5s all;
                            }
                        }
                        input[type="submit"]{
                            position: relative;
                            background-color: transparent;
                            padding: rem-calc(0 30px);
                            margin: 0;
                            height: rem-calc(43px);
                            border-radius: rem-calc(30px);
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            text-indent: -199px;
                            z-index: 11;
                            &:hover{
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            
            .footer_mailtxt{
                // padding: rem-calc(68px 0);
                // padding: 0;
                h4{
                    font-family: 'Roboto';
                    color: $white;
                    font-weight: 400;
                    font-size: rem-calc(14px);
                    line-height: rem-calc(19px);
                    margin-bottom: rem-calc(32px);
                    /*@include bp(lgmin) {
                        font-size: rem-calc(25px);
                    }
                    @include bp(lg) {
                        width: 66%;
                    }
                    @include bp(tablet) {
                        width: 100%;
                        text-align: center;
                        line-height: 1.3;
                    }*/
    
                    strong{
                        font-family: 'Titillium Web';
                        font-weight: 700;
                        font-size:  rem-calc(19px);
                        line-height: rem-calc(22px);
                        margin-top: rem-calc(20px);
                        margin-bottom: rem-calc(15);
                        display: block;
                    }
                }
            }
    
            .ftr_mailbox{
                position: relative;
                width: 100%;
                padding: 0;
                margin: 0;
                input[type="email"]{
                    font-family: 'Titillium Web';
                    font-size: rem-calc(14px) !important;
                    color: $white !important;
                    border-radius: rem-calc(30px);
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    height: rem-calc(43px);
                    padding: rem-calc(5px 20px);
                    box-shadow: none;
                    max-width: 90%;
                    text-align: center;
                    background-color: transparent !important;
                    border: 1px solid $yellow !important;
                    &::placeholder{
                        font-family: 'Titillium Web';
                        font-size: rem-calc(14px) !important;
                        color: $white !important;
                    }
                }
    
                input[type="email"]::-webkit-input-placeholder {color: #6c6968;}
                input[type="email"]::-moz-placeholder {color: #6c6968;}
                input[type="email"]:-ms-input-placeholder {color: #6c6968;}
                input[type="email"]:-moz-placeholder {color: #6c6968;}
                
                input:-webkit-autofill,
                input:-webkit-autofill:hover, 
                input:-webkit-autofill:focus{
                    transition: background-color 5000s ease-in-out 0s;
                    -webkit-text-fill-color: $white;
                }
            }
        }
    }

    .footer_mail{
        .footer_mailtxt{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // padding: rem-calc(22px 0 5px);
            padding: 0;

            h3{
                font-weight: 500;
                width: 62%;
                padding: rem-calc(5px 0 0);

                @include bp(lgmin) {
                    font-size: rem-calc(25px);
                }
                @include bp(lg) {
                    width: 66%;
                }
                @include bp(tablet) {
                    width: 100%;
                    text-align: center;
                    line-height: 1.3;
                }

                strong{
                    padding: rem-calc(0 15px 0 0);
                    @include bp(tablet) {
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        .ftr_mailbox{
             display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 38%;
            padding: rem-calc(0 0 0 31px);

            @include bp(lg) {
                width: 34%;
            }

            @include bp(tablet) {
                max-width: rem-calc(361px);
                width: 100%;
                padding: rem-calc(5px 0 0);
                margin: 0 auto;
            }

            input[type="email"]{
                width: calc(100% - 87px);
                border-radius: rem-calc(12px);
                height: rem-calc(42px);
                padding: rem-calc(5px 20px);
                box-shadow: none;
            }

            input[type="email"]::-webkit-input-placeholder {color: #6c6968;}
            input[type="email"]::-moz-placeholder {color: #6c6968;}
            input[type="email"]:-ms-input-placeholder {color: #6c6968;}
            input[type="email"]:-moz-placeholder {color: #6c6968;}

            button{
                width: rem-calc(72px);
                margin: rem-calc(0 3px 0 0);
                background:$white;
                border-radius: rem-calc(10px);
                color: $orange;
                font-weight: 600;
                text-transform: uppercase;
                font-size: rem-calc(17px);
                line-height: 0;
                height: rem-calc(42px);
                cursor: pointer;
                transition:0.5s all; 

                &:hover , &:focus{
                    color: $white;
                    background:$orange;
                }
            }
        }
    }

    .footer_help{
        position: relative;
        z-index: 1;

        &.innerspacing{
            padding-bottom: rem-calc(200px);
            margin-bottom: rem-calc(-180px);
            @include bp(lgmin) {
                padding: rem-calc(82px 0 200px); 
            }

            @include bp(xxlmin) {
                margin-bottom: rem-calc(-234px); 
                padding: rem-calc(82px 0 260px); 
            }

            @include bp(lg) {
                padding-bottom: rem-calc(140px);
                margin-bottom: rem-calc(-113px); 
            }

            @include bp(tablet) {
                padding-bottom: rem-calc(90px);
                margin-bottom: rem-calc(-72px); 
            }
            @include bp(sm) {
                padding-bottom: rem-calc(80px);                
            }

        }

        .contact_artbg{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-size: 1600px !important;
            opacity: 0.7;
        }

        .footer_helptxt{
            h2{
                @include bp(lgmin) {
                    margin-bottom: rem-calc(21px);
                }
                line-height: 1.55;
                text-transform: uppercase;
                @include bp(sm) {
                    line-height: normal;
                }
                span{
                    font-weight: $light;
                    display: block;
                    text-transform: none;
                }
            }

            p{
                @extend .mdtxt;
                line-height: 1.47;
            }

            .footer_helpbtn{
                margin-top: rem-calc(40px);
            }
        } 
    }

    .contact_growbox{ 
        position: relative;
        z-index: 3;
        padding-bottom: 0;

        .contact_growblock{
            background: $darkgrey;
            color: $white;
            padding:rem-calc(56px 20px 51px 20px);            
            width: 44.2%; 
            margin: 0 auto 22px;

            @include bp(lg) {
                padding: rem-calc(46px 20px 41px 56px);
                margin: 0 auto 10px;
            }
            @include bp(tabletmd) {
                padding: rem-calc(26px 20px 21px 36px);
                width: 100%;
                max-width: rem-calc(390px);
            }
             @include bp(sm) {
                width: 100%;
                padding: rem-calc(20px);                
             }

            h2{
                @include bp(lgmin) {
                    margin: rem-calc(0 0 9px 0); 
                }
            }
            p{
                letter-spacing: 1px;
            }

            .contact_artbtn{
                margin: rem-calc(31px 0 0);

                .btn{
                    @include bp(lgmin) {
                        padding: rem-calc(10px 17px);
                    }
                }
            }
        }
    }
    .hs-fieldtype-select {
        display: none;
    }
} 

.footer_art.no-layout{
    @include bp(tabletmd) {
       
    }
}