
.video_fullbox{
	&.innerspacing{	
		padding: rem-calc(35px 0 93px);

		@include bp(sm) {
			padding: rem-calc(35px 0 53px); 	
		}
	}

	.pattern{
		background-repeat: no-repeat !important; 
		background-size: cover!important;
		height: 100%;
	}

		
		h2{

			text-transform: uppercase;
			@include bp(lgmin) {
				margin-bottom: rem-calc(21px);
				letter-spacing: 2px;
			}
		}
}

.video_fullboxbottom{
	position: relative;
	z-index: 2;
	.grid-container{		
			max-width:rem-calc( 894px);
	}

	.video_boxpart{
		padding: 29.5% rem-calc(30px);
		margin: rem-calc(-87px 0 0);
		background: #fff;
		position: relative;

		@include bp(tablet) {
			padding: 29.5% rem-calc(15px);
			
		}
		@include bp(sm) {
			margin:rem-calc( -47px 0 0);
		}

		iframe{
			position: absolute;
			width: calc(100% - 60px);
			height: calc(100% - 60px);
			top: 27px;

			@include bp(tablet) {
				width: calc(100% - 30px);
				height: calc(100% - 30px);
				top: rem-calc(15px);
			}
		}
	}
}
