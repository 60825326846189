  
.roundimgtext{

	&.innerspacing{	
	margin-bottom: rem-calc(17px);	
		@include bp(lgmin) {
			padding: rem-calc(76px 0 105px);
		}
	}

	.roundimgtext_row{
		.large-4{
			text-align: right;
			@include bp(sm) {
				text-align: center;
			}
		}

		.large-8{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
		}

		.round_peopleimg{
			display: inline-block;
		}

		.roundimgtext_info{
			padding: rem-calc(31px 0 31px);

			@include bp(tabletmd) {
				padding: rem-calc(21px 0 21px);
			}
			@include bp(tablet) {
				padding: rem-calc(15px 0 20px);
			}
			@include bp(sm) {
				text-align: center;
				padding: rem-calc(0 0 20px);
			}

			h2{
				@include bp(lgmin) {
					margin-bottom: rem-calc(8px);
				}
			}

			a:not(.btn){
				text-decoration: underline;
			}
			p{
				letter-spacing: 1.1px;
			}

			ul{
				@extend .bulletlist;
			}
		}


		&:nth-child(even){
			flex-flow: row-reverse;
			@include bp(sm) {
				flex-flow:wrap;
			}
			.large-4{
				text-align: left;
				@include bp(sm) {
					text-align: center;
				}
			}

		}
	}

}

.round_peopleimg.roundside_txt{	
	.round_person{
		@include bp(lgmin) {
			height: rem-calc(302px);
			width: rem-calc(302px);
		}
		@include bp(tabletmd) {
			padding: rem-calc(10px);
			height: rem-calc(224px);
			width: rem-calc(224px);
		}
		@include bp(tablet) {		
			padding: rem-calc(5px);
			height: rem-calc(180px);
			width: rem-calc(180px);
		}
	}

	.round_personimg{
		@include bp(lgmin) {
			height: rem-calc(270px);
			width: rem-calc(270px);
		}
		@include bp(tabletmd) {
			height: rem-calc(204px);
			width: rem-calc(204px);
		}
		@include bp(tablet) {
			height: rem-calc(170px);
			width: rem-calc(170px);
		}
	}
}
