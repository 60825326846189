/*************************
Mixins
*************************/

/************ Placeholder ************/
@mixin placeholder {
  ::-webkit-input-placeholder {@content};
  :-moz-placeholder           {@content};
  ::-moz-placeholder          {@content};
  :-ms-input-placeholder      {@content};
}

/************ Transition *************/
@mixin transition($element, $time, $transition-name) {
	-webkit-transition: $element $time $transition-name;
	-moz-transition: $element $time $transition-name;
	-ms-transition: $element $time $transition-name;
	-o-transition: $element $time $transition-name;
	transition: $element $time $transition-name;
}

/************ Breakpoints *************/
@mixin bp($point) {
	@if $point == xxsm {
		@media (max-width: 360px) {
			@content;
		}
	} @else if $point == xsm {
		@media (max-width: 479px) {
			@content;
		}
	} @else if $point == sm {
		@media (max-width: 639px) {
			@content;
		}
	} @else if $point == tabletsm {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 768px) {
			@content;
		}
	} @else if $point == tabletmd {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == tabletXl {
		@media (max-width: 1023px) {
			@content;
		}
	} @else if $point == lg {
		@media (max-width: 1200px) {
			@content;
		}
	} @else if $point == lgmin {
		@media (min-width: 1201px) {
			@content;
		}
	} @else if $point == xl {
		@media (max-width: 1366px) {
			@content;
		}
	} @else if $point == xlmin {
		@media (min-width: 1366px) {
			@content;
		}
	} @else if $point == xxlmin {
		@media (min-width: 1921px) {
			@content;
		}
	}
}
