
.branding_list{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(113px 0 70px);
		}
	}



	.pattern{
		background-repeat: no-repeat !important; 
	}

	.top_txt{
		@include bp(lgmin) {
			margin-bottom: rem-calc(34px);
		}
		
		h2{
			text-transform: uppercase;
			@include bp(lgmin) {
				margin-bottom: rem-calc(11px);
			}
		}
	}

	.brd_listbox{
		position: relative;
		padding: rem-calc(0 0 0 117px);
		margin: rem-calc(30px 0 0);

		@include bp(tabletmd) {
			padding: rem-calc(0 0 0 77px);
		}

		@include bp(tablet) {
			padding: rem-calc(0 0 0 50px);
		}

		@include bp(sm) {
			margin: rem-calc(20px 0 0);
		}

		.brd_listico{
			position: absolute;
			left: rem-calc(44px);
			top: 0;
			width: rem-calc(42px);

			@include bp(tabletmd) {
				left: rem-calc(22px);
			}
			@include bp(tablet) {
				width: rem-calc(32px);
				left: rem-calc(11px);
			}
		}

		h3{
			text-transform: capitalize;
			line-height: 1.2;
			@include bp(lgmin) {
				font-size: rem-calc(25px);
				margin-bottom: 3px;
			}
		}

		p{
			@include bp(lgmin) {
				line-height: 1.5;
			}
		}
	}

	.brd_btn{
		margin-top: rem-calc(23px);

		.btn{
			color: $white;
		}
	}
	

}
