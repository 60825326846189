  
.press_release{

	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(62px 0 57px);
		}
	}

	.secbgimg.pattern{
		background-size: cover !important;
		background-repeat: no-repeat !important;
	}

	.large-6{
		display: flex;
		flex-wrap:wrap;
	}
	

	.press_box{
		position: relative;
		padding: rem-calc(0 0 24px 0);
		margin: rem-calc(20px) 0 calc(20px + 2.9%) 0;
		@include bp(sm) {
			margin: rem-calc(10px 0 10px 0);
		}
		.press_txt{
			h4{
				margin: rem-calc(0 0 2px);
				@include bp(lgmin) {
					font-size: rem-calc(22px);
					line-height: 1.4;
				}
			}

			p{
				font-size: 18px;

				@include bp(sm) {
					font-size:  rem-calc(16);

				}
				
			}
		}

		.read_btn{
			position: absolute;
			bottom: rem-calc(2px);
			.btn{
				background: none;				
				color: #d56c2d;
				border: none;
				padding: 0;
				position: relative;

				&:before{
					position: absolute;
					content: "";
					display: inline-block;
					top:rem-calc(-7px);
					left: 0;
					height: 2px;
					width: 100%;
					background: transparent;
				}

				&:hover{
					background: none;
					&:before{
						background: $yellow;
					}
				}
			}
		}
	}

	

}
