.cpt-table-block{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(68 0 25);
		}
	}	
    .top_txt{ 
        p{
            line-height: 1.47;
        }
    }
    .cpt-table-block-wrapper{
        width:100%;
        padding:rem-calc(50 0 0);
        .cpt-table{
            padding-top:rem-calc(10);
        }
        table {
            border:rem-calc(1) solid #a5a7aa;
            margin: 0;
            min-width: rem-calc(640px);
            tr {
                border-top: 1px solid #a5a7aa;
                border-bottom: 1px solid #a5a7aa;
                border-top: none;
                /*isplay: flex;
                flex-wrap: wrap;*/
                &:last-child {
                    border-bottom: none;
                }    
            }
            thead{
                background: $orange;
                border:none;                
                tr{
                    border-top:none;
                    th{
                        color:$white;  
                        font-size:rem-calc(20);
                        font-family:$roboto;   
                        padding:rem-calc(9px 31px);
                        /*height:rem-calc(42);*/
                        @include bp(tabletmd) {
                            padding:rem-calc(5 15);
                            font-size:rem-calc(18);                            
                        }    
                        &:first-child{
                            border-right:1px solid #738592;
                        }
                        &.half-col{
                            width:50%;
                        }
                    }
                }                
            }        
            tbody{
                border:none;
                tr{
                    td{
                        color:$darkgrey;
                        font-size:rem-calc(18);
                        font-family:$roboto;
                        padding:rem-calc(9.5px 25px);
                        /*height:rem-calc(42); */
                        width: 50%;
                        /*display: flex;
                        flex-wrap: wrap;
                        align-items: center; */
                        word-break: break-word;                      
                        &:first-child{
                            border-right:1px solid #738592;
                        }
                        @include bp(tabletmd){
                            padding:rem-calc(5 15);
                            font-size:rem-calc(18);
                            
                        }
                        @include bp(tabletsm){                            
                            font-size:rem-calc(16);
                            font-size:rem-calc(16);
                        }
                    }
                }
            }
        }
    }
}
