/* ICONS */
.icon:before{font:36px/1 'Socialico';width:32px;margin:0 15px 0 0;text-decoration:none;vertical-align:top;display:inline-block;}
icon.fallback{text-indent:-9999px;overflow:hidden;display:inline-block;position:relative;min-width:16px}
icon.fallback:before{position:absolute;left:0;top:0;text-indent:0}
.circle.icon:before{content:"\0020"}
.soundcloudcircle.icon:before{content:"\0021"}
.mobilemecircle.icon:before{content:"\0024"}
.pinterestcircle.icon:before{content:"\0026"}
.downloadcircle.icon:before{content:"\0028"}
.refreshcircle.icon:before{content:"\0029"}
.flowercircle.icon:before{content:"\002C"}
.starcircle.icon:before{content:"\002D"}
.musiccircle.icon:before{content:"\002E"}
.foursquarecircle.icon:before{content:"\002F"}
.windowscircle.icon:before{content:"\0030"}
.pluscircle.icon:before{content:"\0031"}
.linkcricle.icon:before{content:"\0032"}
.heartcircle.icon:before{content:"\0033"}
.aperturecircle.icon:before{content:"\0034"}
.filmcircle.icon:before{content:"\0035"}
.lastfmcircle.icon:before{content:"\0036"}
.mappincircle.icon:before{content:"\0037"}
.waterdropcircle.icon:before{content:"\0038"}
.qikcircle.icon:before{content:"\0033"}
.messagecircle.icon:before{content:"\003F"}
.applecircle.icon:before{content:"\0040"}
.amazon.icon:before{content:"\0041"}
.bebo.icon:before{content:"\0042"}
.appstore.icon:before{content:"\0043"}
.dribbble.icon:before{content:"\0044"}
.behance.icon:before{content:"\0045"}
.facebook.icon:before{content:"\0046"}
.google.icon:before{content:"\0047"}
.skype.icon:before{content:"\0048"}
.linkedin.icon:before{content:"\0049"}
.deviantart.icon:before{content:"\004A"}
.bing.icon:before{content:"\004B"}
.twitterbird.icon:before{content:"\004C"}
.myspace.icon:before{content:"\004D"}
.flickr.icon:before{content:"\004E"}
.tumblr.icon:before{content:"\004F"}
.paypal.icon:before{content:"\0050"}
.quora.icon:before{content:"\0051"}
.rss.icon:before{content:"\0052"}
.stumbleupon.icon:before{content:"\0053"}
.twitter.icon:before{content:"\0054"}
.blogger.icon:before{content:"\0055"}
.vimeo.icon:before{content:"\0056"}
.wordpress.icon:before{content:"\0057"}
.youtube.icon:before{content:"\0058"}
.yahoo.icon:before{content:"\0059"}
.aol.icon:before{content:"\005A"}
.amazoncircle.icon:before{content:"\0061"}
.bebocircle.icon:before{content:"\0062"}
.appstorecircle.icon:before{content:"\0063"}
.dribbblecircle.icon:before{content:"\0064"}
.behancecircle.icon:before{content:"\0065"}
.facebookcircle.icon:before{content:"\0066"}
.googlecircle.icon:before{content:"\0067"}
.skypecircle.icon:before{content:"\0068"}
.linkedincircle.icon:before{content:"\0069"}
.deviantartcircle.icon:before{content:"\006A"}
.bingcircle.icon:before{content:"\006B"}
.twitterbirdcircle.icon:before{content:"\006C"}
.myspacecircle.icon:before{content:"\006D"}
.flickrcircle.icon:before{content:"\006E"}
.tumblrcircle.icon:before{content:"\006F"}
.paypalcircle.icon:before{content:"\0070"}
.quoracircle.icon:before{content:"\0071"}
.rsscircle.icon:before{content:"\0072"}
.stumbleuponcircle.icon:before{content:"\0073"}
.twittercircle.icon:before{content:"\0074"}
.bloggercircle.icon:before{content:"\0075"}
.vimeocircle.icon:before{content:"\0076"}
.wordpresscircle.icon:before{content:"\0077"}
.youtubecircle.icon:before{content:"\0078"}
.yahoocircle.icon:before{content:"\0079"}
.aolcircle.icon:before{content:"\007A"}
