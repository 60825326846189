.accordionbox{ 
	&.innerspacing{
		@include bp(lgmin) {
			padding:rem-calc(88px 0 76px);
		}
	}


	.top_txt{
		margin:0 auto rem-calc(47px);
		max-width:rem-calc(1000px);

		h2{
			
			font-weight:$normal;			
			text-transform: uppercase;

			@include bp(lgmin) {
				margin-bottom: rem-calc(38px);
			}
		}
		
	}

	.accordion{
		.accordion-item{
			/*border-top: 1px solid #c2c2c2;*/
			border-bottom: 1px solid #738592;
			/*padding: rem-calc(37px 112px 31px 193px);
			padding: rem-calc(49px 112px 52px 193px);*/
			padding: rem-calc(0 112px 0 193px);
			position: relative;


			@include bp(tabletmd) {
				padding: rem-calc(0 62px 0 143px);
			}

			@include bp(tablet) {
				padding: rem-calc(0 42px 0 103px);
			}

			@include bp(sm) {
				padding: rem-calc(0 26px 0 50px);
			}
			

			&:hover , &:focus{
				/*background: rgba($black , .1);*/
			}
			.accordion-title{
				border:none;
				letter-spacing: 0.6px; 
				margin: 0;
				position: static;
				/*padding: 20px 15px 2px;*/
				padding: rem-calc(70px 15px 47px);

				@include bp(tabletmd) {
					font-size: rem-calc(25px);
					padding: rem-calc(50px 15px 34px);
				}

				@include bp(tablet) {
					padding: rem-calc(40px 15px 24px);
				}

				@include bp(sm) {
					padding: rem-calc(15px 5px 3px);
					font-size: rem-calc(20px); 
				}

				&:hover , &:focus{
					background: none;
					opacity: 1;
				}


				&:before{
					content: '';
					background: url(../images/icons/pluse.svg) center no-repeat;
					width: rem-calc(45px);
					height: rem-calc(45px);
					background-size: 100%;
					right: 0;
					top: rem-calc(69px); 

					@include bp(tabletmd) {
						top:rem-calc(55px);
						width: rem-calc(38px);
						height: rem-calc(38px);
					}

					@include bp(tablet) {
						top:rem-calc(38px);
						width: rem-calc(33px);
						height: rem-calc(33px);
					}

					@include bp(sm) {
						top:rem-calc(23px);
						width: rem-calc(23px);
						height: rem-calc(23px);
					}
					
				}

				h2{					
					font-weight: $bold;
					letter-spacing: 1.5px;
					@include bp(lgmin) {
						font-size: rem-calc(24px);						
					}
					@include bp(lg) {
						font-size:  rem-calc(23);
						line-height: rem-calc(32);
					}
					@include bp(tabletmd) {
						font-size:  rem-calc(21);
						line-height: normal;
						letter-spacing: 1px;
					}
					@include bp(tablet) {
						font-size:  rem-calc(20);		

					}
					@include bp(xsm) {
						font-size:  rem-calc(18);			
					}
				}

				.acc_icon{
					display: block;
					position: absolute; 
					top: rem-calc(22px);
					left: rem-calc(14px);
					width: rem-calc(161px);
					transition: 0.5s all;

					@include bp(tabletmd) {
						top: rem-calc(21px);
						width: rem-calc(111px);
					}
					@include bp(tablet) {
						top: rem-calc(22px);
						width: rem-calc(81px);
					}
					@include bp(sm) {
						top: rem-calc(10px);
						width: rem-calc(41px);
						left: rem-calc(5px);
					}
				}
			}

			.accordion-content{
				padding-top: 0;
				padding-bottom: rem-calc(46px);

				@include bp(tablet) {
					padding-bottom: rem-calc(30px);
				}

				@include bp(sm) {
					padding:rem-calc( 0 5px 20px);
				}

				p{
					line-height: 1.4;
					margin-bottom: rem-calc(10px);
				}	

				ul{
					@extend .orangetick_list;
					margin: rem-calc(17px 0 0 2px);
				}

				.learnbtn{
					
					a{
						font-weight: $bold;
						color: $orange;
						font-size: rem-calc(16px);
						letter-spacing: 0.5px;

						&:hover{
							color: $yellow;
							opacity: 1;
						}

						@include bp(tablet) {
							font-size: rem-calc(14px);
						}
					}
				}	

				.btn{
					min-width: rem-calc(100px);
					@include bp(lgmin) {
						min-width: rem-calc(186px);
					}


				}		
			}


			&.is-active{
				.accordion-title{
					margin-bottom: -5px;
					padding: rem-calc(57px 15px 0);

					@include bp(tablet) {
						padding: rem-calc(47px 15px 0);
					}
					@include bp(sm) {
						padding: rem-calc(27px 5px 0);
					}
					&:before{						
						background: url(../images/icons/minus.svg) center no-repeat;
						background-size: 100%;
						top: rem-calc(57px);	
						@include bp(sm) {
							top: rem-calc(36px);
						}			
					}
					.acc_icon{
						top: rem-calc(17px);
						@include bp(sm) {
							top: rem-calc(26px);
						}
					}
				}
				&.accordion-item{
					/*padding: 37px 112px 31px 193px;
					padding: 0 112px 0 193px;

					@include bp(tabletmd) {
						padding: 0 62px 0 143px;
					}

					@include bp(tablet) {
						padding: rem-calc(0 42px 0 103px);
					}*/
				}
			}
		}

		.accordion-content{
			border:none;
		}
	}


	
}


.open_position-template-default .at-share-dock-outer.addthis-smartlayers.addthis-smartlayers-mobile {
    display: none;
}

.open_position-template-default .addthis-smartlayers.addthis-smartlayers-desktop {
    display: none;
}