  
.fifty_block {
	@include bp(lgmin) {
		margin-top: rem-calc(-8px);
	}

	.round_peopleimg {	
		@include bp(sm) {
			text-align: center!important;
		}	
		.round_person{
			display: inline-block;
		}
	}

	.grid-x{
		@include bp(sm) {
			text-align: center;
		}
		.large-6{
			@include bp(tablet) {
				padding-top: rem-calc(20px);
			}
			@include bp(sm) {
				padding-top: 0;
			}
		}
	}

	.roundimgtext_info{
		padding: rem-calc(52px 0 0);
		@include bp(tabletmd) {
			padding: 0;
		}
		@include bp(sm) {
			padding-bottom: rem-calc(20px);
		}
		h2{
			font-size:  rem-calc(24);
			letter-spacing: 1.2px;
			margin-bottom: rem-calc(10px);
			@include bp(tabletmd) {
				font-size:  rem-calc(18);
			}
			@include bp(tablet) {
				line-height: normal;
				font-size:  rem-calc(17);


			}
			@include bp(xsm) {
				font-size:  rem-calc(16);		

			}
		}

		p{
			font-size: rem-calc(18px);
			line-height: 1.4;
			@include bp(sm) {
				font-size:  rem-calc(15);

			}			
		}
	}
	.media_txt{
		.round_peopleimg {
			text-align: right;
		}
		
	}

	.text_media{
		flex-flow: row-reverse;
		@include bp(sm) {
			flex-flow: wrap;
		}
		.round_peopleimg {
			text-align: left;
		}
		.roundimgtext_info{
			@include bp(lgmin) {
				padding: rem-calc(34px 0 0);
			}
		}
	}

	.grid-container .text_text:first-child{
		.roundimgtext_info{
			padding-top: 0;
		}
	}

	.fifty_btn{
		margin-top: rem-calc(10px);
		@include bp(sm) {
			margin-top: 0;
		}
		.btn{
			display: inline-block;
		}
	}
}

