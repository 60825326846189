/*************************
Font Family
*/

$titilliumWeb: "Titillium Web";
$roboto: "Roboto";

/* ----- Font weight ------- */
$thin: 200;
$light: 300;
$normal: 400;
$medium: 500;
$mediumdark: 600;
$bold: 700;
$heavy: 800;
$blackwht: 900;

/* ------------------ */

/* Color */

/*$yellow: #edb944;*/
$orange: #d56c2d;
$green-txt: #a0ac5b;
$darkgrey: #212121;
$blue-txt: #4084c2;
/*$lightgrey: #ebebeb;*/
$white: #fff;
$black: #000;
$white-smoke: #f3f3f3;
$pale: #738592;

$blacktop: #212121;
$lightgrey: #F8F7F6;
$yellow:#FFC300;
$green: #7BBA61;


/*social fonts*/
@font-face {
	font-family: 'Socialico';
	src: url('../fonts/Socialico.eot');
	src: url('../fonts/Socialico.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Socialico.woff') format('woff'),
	url('../fonts/Socialico.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* Titillium Web Fonts */
@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('../fonts/TitilliumWeb-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('../fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
}
@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('../fonts/TitilliumWeb-Bold.ttf') format('truetype');
}
.titillium-ft{
	font-family: 'Titillium Web';
}
.titillium-ft-md{
	font-family: 'Titillium Web';
	font-weight: 600;
}
.titillium-ft-bd{
	font-family: 'Titillium Web';
	font-weight: 700;
}

/* Roboto Fonts */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}
.roboto-ft{
	font-family: 'Roboto';
}
.roboto-ft-bd{
	font-family: 'Roboto';
	font-weight: 700;
}

.whitefont {
	color: $white;
}

body {
	font-size: rem-calc(20);
	line-height: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	font-family: $roboto;
	font-weight: $normal;
	color: $darkgrey;
	min-height: auto !important;

	@include bp(tabletmd) {
		font-size: rem-calc(18);
	}
	@include bp(sm) {
		font-size: rem-calc(16);
	}
}

a {
	text-decoration: none;
	@include transition(0.5s, all, ease);

	&:hover, &:focus {
		background: none;
		box-shadow: none;
	}
}

.graytxt {
	color: $darkgrey;
}

.whitetxt {
	color: $white;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	margin-bottom: rem-calc(18);
	font-family: $titilliumWeb;
	line-height: normal;
	font-weight: $light;
	@include bp(tabletmd) {
		> br {
			display: none;
		}
	}
}

h1 {
	font-size: rem-calc(70);
	line-height: rem-calc(58);
	font-weight: $blackwht;
	margin-bottom: rem-calc(11px);
	text-transform: uppercase;

	@include bp(lg) {
		font-size: rem-calc(60);
		line-height: rem-calc(52);
	}
	@include bp(tabletmd) {
		font-size: rem-calc(50);
		line-height: normal;
	}
	@include bp(tablet) {
		font-size: rem-calc(40);

	}
	@include bp(xsm) {
		font-size: rem-calc(30);
	}
}


h2 {
	font-size: rem-calc(35);
	line-height: 1.3;
	margin-bottom: rem-calc(16);
	font-weight: $bold;
	letter-spacing: -0.5px;

	@include bp(lg) {
		font-size: rem-calc(30);
	}
	@include bp(tabletmd) {
		font-size: rem-calc(28);
		line-height: normal;

		> br {
			display: none;
		}
	}
	@include bp(tablet) {
		font-size: rem-calc(26);
		margin-bottom: rem-calc(12);

	}
	@include bp(xsm) {
		font-size: rem-calc(20);
		margin-bottom: rem-calc(10);

	}
}

.h2big {
	font-size: rem-calc(50px);
	@include bp(lg) {
		font-size: rem-calc(45);
	}
	@include bp(tabletmd) {
		font-size: rem-calc(40);
		line-height: normal;

		> br {
			display: none;
		}
	}
	@include bp(tablet) {
		font-size: rem-calc(35);
	}
	@include bp(xsm) {
		font-size: rem-calc(30);
	}
}

h3 {
	font-weight: $bold;
	font-size: rem-calc(28);
	line-height: rem-calc(36);
	margin-bottom: rem-calc(11);

	@include bp(lg) {
		font-size: rem-calc(23);
		line-height: rem-calc(32);

		> br {
			display: none;
		}
	}
	@include bp(tabletmd) {
		font-size: rem-calc(21);
		line-height: normal;
	}
	@include bp(tablet) {
		font-size: rem-calc(20);

	}
	@include bp(xsm) {
		font-size: rem-calc(18);
	}
}

h4 {
	font-size: rem-calc(20);
	font-weight: $bold;
	line-height: rem-calc(28);
	color: $darkgrey;
	margin-bottom: rem-calc(20);

	@include bp(lg) {
		font-size: rem-calc(19);
	}
	@include bp(tabletmd) {
		font-size: rem-calc(18);
	}
	@include bp(tablet) {
		font-size: rem-calc(17);
		line-height: rem-calc(24);

	}
	@include bp(xsm) {
		font-size: rem-calc(16);
		line-height: rem-calc(21);
	}
}

h5 {
	font-size: rem-calc(18);
	font-weight: normal;
	line-height: normal;
	margin-bottom: rem-calc(14);

	@include bp(tabletmd) {
		font-size: rem-calc(17);
	}
	@include bp(tablet) {
		font-size: rem-calc(16);
	}
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}


.btn {
	font-size: rem-calc(14);
	font-weight: $bold;
	letter-spacing: 0.8px;
	color: $blacktop;
	background: $yellow;
	border: solid 2px $yellow;
	display: inline-block;
	padding: rem-calc(6 70);
	text-align: center;
	line-height: normal;
	cursor: pointer;
	text-transform: initial;
	font-family: $titilliumWeb;
	transition: 0.5s all;
	border-radius: 30px;
	// width: 50%;

	&:hover, &:focus {
		opacity: 1;
		border: 2px solid $yellow;
		color: $blacktop;
		background: $white;
	}

	@include bp(lg) {
		padding: rem-calc(7 70);
		font-size: rem-calc(15);
	}

	@include bp(tablet) {
		padding: rem-calc(8 72);
		font-size: rem-calc(14);

	}
	@include bp(xsm) {
		padding: rem-calc(9 68);
		font-size: rem-calc(13);
	}

	&.ghostbtn {
		background: none;
		border: 2px solid $yellow;
		color: $blacktop;
		font-family: $titilliumWeb;

		&:hover, &:focus {
			background: $yellow;
			color: $blacktop;
		}
	}

	&.whiteghostbtn {
		@extend .ghostbtn;
		border: 2px solid $white;
		color: $white;

		&:hover, &:focus {
			background: $white;
			color: $darkgrey;
		}
	}

	&.whitebtn {
		background: $white;
		color: $darkgrey;
		border: 2px solid $white;

		&:hover, &:focus {
			background: none;
			border: 2px solid #fff;
			color: $white;
		}
	}

	&.darkgrey {
		background: $darkgrey;
		border: 2px solid $darkgrey;

		&:hover, &:focus {
			background: $orange;
			opacity: 1;
			border: 2px solid $orange;
			color: $white;
		}
	}

	&.greenbtn {
		background: $green-txt;
		border: 2px solid $green-txt;

		&:hover, &:focus {
			background: $darkgrey;
			opacity: 1;
			border: 2px solid $darkgrey;
			color: $white;
		}
	}

	&.yellowbtn {
		background: $yellow;
		border: 2px solid $yellow;
		color: $darkgrey;

		&:hover, &:focus {
			background: none;
			opacity: 1;
			border: 2px solid $yellow;
			color: $yellow;
			background: none;
		}
	}
}

a {
	color: $orange;

	&:hover, &:focus {
		opacity: 0.7;
		color: inherit;
	}

	&.btn {
		&:hover, &:focus {
			opacity: 1;
		}
	}
}


select {
	display: inline-block;
	width: rem-calc(250);
	max-width: 100%;
	font-size: rem-calc(18);
	font-family: $titilliumWeb;
	padding: rem-calc(15px 38px 15px 19px);
	margin-bottom: 0;
	border: 1px solid $darkgrey !important;
	color: #6c6968;
	@include background-triangle($orange);
	cursor: pointer;
	height: auto;
	background-size: 12px;
	background-position: top 8px right -27px;
	line-height: 1.3;
	background-color: transparent;

	@include bp(tablet) {
		height: auto;
		font-size: rem-calc(15);
		background-position: top 7px right -17px;
		padding: rem-calc(10px 28px 10px 9px);
	}


	option {
		font-size: rem-calc(16);
		background-color: $white;
		border: 1px solid $darkgrey;

		@include bp(tablet) {
			font-size: rem-calc(14);
		}
	}
}

ul {
	margin-left: 10px;
}

.bulletlist {
	list-style: none;
	margin-left: 0;

	@include bp(sm) {
		max-width: 320px;
		text-align: left;
		margin: 0 0 20px 10px;
	}

	li {
		position: relative;
		padding: 0 0 0 19px;
		line-height: 1.4;

		&:before {
			content: "";
			display: inline-block;
			height: 6px;
			width: 6px;
			border-radius: 50%;
			background: #d56c2d;
			position: absolute;
			left: 1px;
			top: 12px;
		}
	}
}

.orangetick_list, .open_txt ul {
	list-style: none;
	margin: 0;
	text-align: left;

	> li {
		position: relative;
		padding: rem-calc(0 0 4px 15px);
		line-height: 1.2;
		font-family: $titilliumWeb;

		&:before {
			content: "";
			position: absolute;
			top: rem-calc(4px);
			left: -3px;
			background: url(../images/icons/orangelist_tick.png;) center no-repeat;
			width: rem-calc(15px);
			height: rem-calc(15px);
			background-size: 100%;
		}
	}
}


.numlist {
	list-style: none;
	counter-reset: li;
	margin: 0;

	li {
		position: relative;
		color: $white;
		counter-increment: li;
		padding: rem-calc(0 0 0 26px);
		line-height: 1.9;
		font-weight: $mediumdark;
	}

	li::before {
		content: counter(li) '.';
		color: $green-txt;
		display: inline-block;
		font-family: $titilliumWeb;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.bgimage {
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
}

p {
	line-height: 1.6;
	margin-bottom: rem-calc(20);

	@include bp(lg) {
		br {
			display: none;
		}
	}

	@include bp(tabletmd) {
		margin-bottom: rem-calc(18);
	}

	@include bp(tablet) {
		line-height: normal;
		margin-bottom: rem-calc(15);
	}
	@include bp(xsm) {
		margin-bottom: rem-calc(10);
	}

	&.bigtxt {
		font-size: rem-calc(28);
		line-height: 1.3;
		font-weight: $bold;

		@include bp(tablet) {
			font-size: rem-calc(24px);
			line-height: normal;
		}
		@include bp(sm) {
			font-size: rem-clac(22px);
		}
		@include bp(xsm) {
			font-size: rem-calc(16px);

		}
	}

	&.mdtxt {
		font-size: rem-calc(22);
		line-height: 1.45;
		@include bp(tabletmd) {
			font-size: rem-calc(18);
		}
		@include bp(tablet) {
			line-height: normal;
			font-size: rem-calc(17);
		}
		@include bp(xsm) {
			font-size: rem-calc(16);
		}
	}
}

.innerspacing {
	padding: rem-calc(95 0);

	@include bp(lg) {
		padding: rem-calc(68 0);
	}
	@include bp(tabletmd) {
		padding: rem-calc(58 0);
	}
	@include bp(tablet) {
		padding: rem-calc(40 0);
	}
	@include bp(x-small) {
		padding: rem-calc(45 0);
	}
	@include bp(xsm) {
		padding: rem-calc(30 0);
	}
}

.outerspacing {
	margin: rem-calc(95 0);

	@include bp(lg) {
		margin: rem-calc(65 0);
	}
	@include bp(tabletmd) {
		margin: rem-calc(55 0);
	}
	@include bp(tablet) {
		margin: rem-calc(45 0);
	}
	@include bp(xsm) {
		margin: rem-calc(35 0);

	}
}

.edit-link {
	display: none;
}


.typo_txt {
	max-width: rem-calc(1243);
	margin: 0 auto;
	@include bp(lg-min) {
		&.innerspacing {
			padding: rem-calc(73 0 80);
		}
	}
}

*:focus {
	outline: none !important;
}

/*pagination*/

.pagination {
	margin: rem-calc(30 0 47);
	text-align: right;
	width: 100%;

	@include bp(lg) {
		margin: rem-calc(28 0);
	}
	@include bp(tablet) {
		margin: rem-calc(26 0);
	}
	@include bp(xsm) {
		margin: rem-calc(20 0);
	}

	li {
		vertical-align: middle;
		padding: rem-calc(0 5 11);
		margin: 0;
		@include bp(lg) {
			padding: rem-calc(0 4 11);
		}
		@include bp(tablet) {
			padding: rem-calc(0 3 11);
			display: inline-block;
		}
		@include bp(xsm) {
			padding: rem-calc(0 2 11);
		}

		a, span {
			border: 1px solid $blue-txt;
			font-size: rem-calc(16);
			padding: rem-calc(2 7);
			color: $blue-txt;
			font-weight: $bold;
			min-width: rem-calc(37);
			width: rem-calc(32px);
			height: rem-calc(31px);
			border-radius: 60%;
			display: inline-block;
			line-height: normal;
			text-align: center;

			&:hover, &:focus {
				background: rgba($blue-txt, 0.1);
				color: $blue-txt;
			}
		}

		.current {
			padding: rem-calc(2 7);
			background: $blue-txt;

			&:hover, &:focus {
				background: $blue-txt;
				color: $white;
			}
		}

		a.next, a.prev {
			display: none;
		}
	}

	&.unlisted {
		a, span {
			width: rem-calc(32px);
			height: rem-calc(31px);
			border-radius: 60%;
			display: inline-block;
			line-height: normal;
			padding: rem-calc(2 7);
			text-align: center;
			border: 1px solid $blue-txt;
			font-size: rem-calc(16);
			color: $blue-txt;
			font-weight: $bold;
			min-width: rem-calc(37);
			margin: rem-calc(0 5);

			&:hover, &:focus {
				background: rgba($blue-txt, 0.1);
				color: $blue-txt;

			}
		}

		.current {
			background: $blue-txt;
			color: $white;
			font-weight: $medium;

			&:hover, &:focus {
				background: $blue-txt;
				color: $white;
			}
		}

		a.next, a.prev {
			display: none;
		}
	}


}

.scrollshow, .scrolltwoshow {
	opacity: 0;
	transform: translateY(70px);
	transition: 1s all;

	&.imVisible {
		opacity: 1;
		transform: translateY(0);
	}
}


/*!pagination*/


br {
	@include bp(tablet-X) {
		display: none;
	}
}


.top_txt {
	p {
		@extend .mdtxt;
		font-weight: $normal;
	}
}

.parallax {
	@include bp(tablet) {
		transform: translate3d(0, 0, 0) !important;
	}
}

body {
	&:before {
		content: "";
		width: 100%;
		background: $darkgrey;
		height: 140px;
		position: absolute;
		z-index: -1;
		top: 0;
	}
}

.opentext {

	.grid-container {
		max-width: rem-calc(1200px);

		@include bp(lgmin) {
			/*padding-left: rem-calc(100px);*/
		}
	}

	.top_icon {
		img {
			width: 210px;
			margin: 0 auto 12px;
			display: block;
		}
	}

	h2 {
		@include bp(lgmin) {
			margin: rem-calc(0 0 28px 0);
		}
	}

	p {
		line-height: 1.48;
	}

	ul, ol {
		text-align: left;
		display: inline-block;
	}

	a:not(.btn) {
		color: $yellow;

		&:hover {
			color: $orange;
			opacity: 1;
		}
	}

	.secbgimg.pattern {
		background-repeat: no-repeat !important;
		background-size: cover !important;
	}
}

.mobfull.cell {
	@include bp(xsm) {
		width: 100%;
	}
}

.page-wrap {
	position: relative;
	z-index: 3;
}