  
.our_people{
	margin-top: rem-calc(29px);
	&.innerspacing{	
		padding:rem-calc(0 0 20px 0);
		overflow: visible;
		@include bp(tablet) {
			padding:rem-calc(0 0 5px 0); 
		}

		
	}

	.secbgimg.pattern{
		background-repeat: no-repeat !important;
	}

	.testislide{
		padding: rem-calc(35px 0 0);
	}

	.testislide_item{
		text-align: center;

		p{
			@extend .mdtxt;
			line-height: 1.55;
			margin: rem-calc(0 0 11px 0); 
			@include bp(lgmin) {
				font-size: rem-calc(25px);
				font-style: italic;
			}
		}

		.personname{
			font-weight: bold;
			font-size: rem-calc(17px);


			@include bp(tablet) {
				font-size: rem-calc(15px); 
			}
		}
	}

	.our_peopleslider{
		position: relative;
		top: rem-calc(-22px);
		.ourp_sliditem{
			.person_info{
				color: $white;
			}

			.large-5{
				display: flex;
				flex-wrap:wrap;
				justify-content: flex-end;

				@include bp(sm) {
					justify-content: center;
				}

				.round_peopleimg-slider{
					max-width: rem-calc(317px);

					@include bp(tablet) {
						max-width: rem-calc(250px);
					}
				}
				.round_peopleimg{
					max-width: rem-calc(339px);
					padding: rem-calc(0 7px 0 0);

					@include bp(sm) {
						margin: 0 auto;
						padding: 0;
					}
				}
			}
		}
		.our_peopleinfo{
			padding: rem-calc(75px 0 15px 70px);

			@include bp(tabletmd) {
				padding: rem-calc(55px 0 15px 0);
				@include bp(sm) {
					padding: rem-calc(25px 0 15px 0);
					text-align: center;
				}
			}

			p{
				margin-top: rem-calc(-2px);
				letter-spacing: 0.9px;
			}

			.ourpeople_btn{
				@include bp(lgmin) {
					margin-top: rem-calc(25px);
					.btn{
						    padding: rem-calc(10px 13px);
					}
				}
			}
		}
	}

	

}

.round_peopleimg{
	text-align: center;
	    
	span{
		display: block; 
	}

	.round_person{
		height: rem-calc(310px);
		width: rem-calc(310px);
		border-radius: 50%;
		overflow: hidden;
		background: $white;
		margin: 0 auto 31px;
		padding: rem-calc(16px);
		transition:0.5s all;
		@include bp(tabletmd) {
			height: rem-calc(278px);
			width: rem-calc(278px);
		}
		@include bp(tablet) {
			padding: rem-calc(10px);
			height: rem-calc(224px);
			width: rem-calc(224px);
		}
		@include bp(sm) {
			margin: 0 auto 15px;			
		}
		.round_personimg{
			height: rem-calc(280px); 
			width: rem-calc(280px);
			border-radius: 50%;
			overflow: hidden;
			transform: scale(1);
			transition:0.5s all;
			@include bp(tabletmd) {
				height: rem-calc(246px);
				width: rem-calc(246px);
			}
			@include bp(tablet) {
				height: rem-calc(204px);
				width: rem-calc(204px);
			}
			
		}

		&:hover{			
			.round_personimg{
				/*transform: scale(1.03);*/
			}
		}
	}

	.person_info{
		font-size: rem-calc(18px);
		@include bp(tablet) {
			font-size: rem-calc(16px);
		}
		@include bp(sm) {
			font-size: rem-calc(15px);
		}
		.person_name{
			font-weight: $bold;
			    margin-bottom: rem-calc(5px);
		}
	}


}


.our_peopleslider{
	.slick-dots{			
		@include bp(lgmin) {
			margin: 0 0 0 10px; 
		}
	}
}


.whitetxt{
	.slick-dots{
		li{
			button{
				&:before{
					content: "";
					background: $white;
					opacity: 1;
					width: rem-calc(14px);
					height: rem-calc(14px);
					border-radius: 50%;
					line-height:rem-calc( 14px);
					transition:0.5s all;

				}
				&:hover{
					&:before{
						background:$yellow;
					}
				}
			}

			&.slick-active {
				button{
					&:before{
						background: $orange;
					}
				}
			}
		}
	}
}

