 

.web_single{
	.banner.inner_bnr{

		@include bp(lgmin) {		
			padding: rem-calc(44px 0 90px);
		}

		@include bp(tabletmd) {
			padding-bottom: rem-calc(80px);
		}
		@include bp(tablet) {
			padding-bottom: rem-calc(70px);

		}
		@include bp(sm) {
			padding-bottom: rem-calc(60px);
		}
	}
}
.web_singlefpo{
	position: relative;
	z-index: 2;
	.grid-container{
		max-width: rem-calc(900px);
	}


	.fpo_img{
	    margin: rem-calc(-66px -20px 26px);

	    @include bp(tabletmd) {
			margin: rem-calc(-56px -0 26px);
		}
		@include bp(tablet) {
			margin: rem-calc(-46px 0 26px);

		}
		@include bp(sm) {
			margin: rem-calc(-36px 0 20px);
		}
	}

	p{
		line-height: 1.4;
		margin-bottom: rem-calc(10px);
	}


}

.fpo_img{
	text-align: center;
	padding: rem-calc(16px);
	background: $white;
	margin: rem-calc(-66px 0 0);
	position: relative;
	z-index: 1;
	min-height: rem-calc(300px);

	@include bp(sm) {
		padding: 8px;
		min-height: rem-calc(200px);
	}

	h2{
		display: inline-block;
		color: $white;
		background: #000;
		margin: rem-calc(87px 0);
		text-transform: uppercase;
		padding: rem-calc(17px 28px);
		@include bp(lgmin) {		
			font-size: rem-calc(40px);
		}

		@include bp(tabletmd) {
			margin: rem-calc(77px 0);
			padding: rem-calc(15px 25px);
		}
		@include bp(tablet) {
			margin: rem-calc(67px 0);
			padding: rem-calc(13px 20px);

		}
		@include bp(sm) {
			margin: rem-calc(47px 0);
			padding: rem-calc(11px 18px);
		}
	}

	.fpo_bg{
		height:calc(100% - 37px);
		position: absolute;
		width: calc(100% - 37px); 
		z-index: -1;

		@include bp(sm) {
			height:calc(100% - 16px);
			width: calc(100% - 16px); 
		}
	}
}
