  
.video_list{

	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(75px 0 105px);
		}
	}

	.top_txt{
		margin-bottom: rem-calc(43px);

		@include bp(sm) {
			margin-bottom: rem-calc(30px);
		}
		h2{
			color: $orange;
			margin-bottom: rem-calc(10px);
		}
	}

	.video_listrow{
		.video_listbox{
			margin: rem-calc(35px 0 0);
			@include bp(sm) {
				margin: rem-calc(20px 0 0);
			}
			.vidlist_thumb{
				position: relative;
				padding: 0 0 56% 0;
				border: 1px solid #000;
				display: block;

				>span{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	} 

	&.singlevid_row{
		&.innerspacing{		
			@include bp(lgmin) {
				padding: rem-calc(72px 0 70px);
			}
		}

		.top_txt{
			margin-bottom: 0;
		}

		.video_listrow{
			justify-content: center;
			.video_listbox{
				@include bp(lgmin) {
					margin-top: rem-calc(28px);
				}
			}
		}
	}

	.vidlist_btn{
		margin-top: rem-calc(41px);
		@include bp(lgmin) {
			margin-top: rem-calc(30px);
		}
	}

}
