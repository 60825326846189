
.subpagicons{

	&.innerspacing{
		padding-bottom: calc(11% + 26px);		
		@include bp(lgmin) {
			padding: rem-calc(100px 0 133px);
		}
	}

	.top_txt{
		margin-bottom: rem-calc(30px);
		@include bp(lgmin) {
			margin-bottom: rem-calc(75px);
		}
	}

	

	.subico_box{
		margin-bottom: 5%; 

		@include bp(lgmin) {
			margin-bottom: rem-calc(43);
		}

		@include bp(tabletmd) {
			margin-bottom: rem-calc(30);
		}

		@include bp(sm) {
			margin-bottom: rem-calc(20);
		}


		.subicobox_info{
			display: flex;
			flex-wrap:wrap;
			justify-content: space-between;


			> a{
				display: block;

				&:hover , &:focus{
					opacity: 1;
				}
			}

			.subicobox_img{
				width: 38%;				
				margin: 0 auto rem-calc(22);
				padding:rem-calc(0 17px 0);	

				@include bp(tabletmd) {					
					width: 40%;
				}

				@include bp(sm) {					
					width: 100%;
					max-width: rem-calc(240px);
					margin: 0 auto rem-calc(15);
					padding:rem-calc(0);
				}

				@include bp(xsm) {					
					
				}

			}

			.subicobox_txt{
				width: 62%;
				padding: rem-calc(28px 0 0 15px);

				@include bp(lgmin) {
					padding: rem-calc(28px 0 0 32px);
				}

				@include bp(tabletmd) {					
					width: 60%;
				}

				@include bp(tablet) {                    
					padding: rem-calc(10px 0 0 10px);
				}

				@include bp(sm) {					
					width: 100%;
					text-align: center;
					padding: rem-calc(10px 0 20px 0);
				}

				ul{
					@extend .orangetick_list;
				}
				

				p{
					letter-spacing: 0.8px;
					margin-bottom: rem-calc(10px);
				}

				ul + .subicobox_btn{
					margin: rem-calc(2px 20px 0);
				}

				.subicobox_btn{		
				    /* margin: rem-calc(2px 20px 0);	 */		
					a{
						color: #4a5155;
						font-weight: $bold;
						font-size: rem-calc(16px);
						&:hover{
							color: $yellow;
							opacity: 1;
						}
						@include bp(sm) {
						font-size: rem-calc(14px);
						}
					}
				}

			}

			.subicobox_txt{
				ul{
					@extend .orangetick_list;
					@include bp(sm) {
						max-width: rem-calc(300px);
						margin: auto;					
					}
				}
			}

			
		}


	}

	.subpagicon_row:nth-child(odd){
		flex-flow:row-reverse;
		justify-content: flex-end;

		.subico_box{
			@include bp(lgmin) {
				margin-bottom: rem-calc(63px);
			}
			.subicobox_img{
				text-align: left;
				@include bp(lgmin) {
					width: 36%;

				}
			}

			.subicobox_txt{
				@include bp(lgmin) {
					width: 61%;
				}
			}
		}

		.subicobox_info{
			.subicobox_txt{
				    padding: rem-calc(25px 50px 0 6px);

				    @include bp(sm) {
				    	padding: rem-calc(25px 0 0 0);
				    }
			}
		}
		
	}

	.subpagicon_row{
		.hide-for-small{
			@include bp(sm) {
				display: none;
			}
		}
	}

	

}
