.new-banner{
	position: relative;
	&.innerspacing{
		@include bp(lgmin) {
			padding: rem-calc(95px 0 65px); 
		}
	}
	
	&.banner-inner-space {
		padding: rem-calc(208 0 300 0);
		// min-height: 900px;

		@include bp(lg) {
			padding: rem-calc(208 0 300 0);
		}
		@include bp(xxlmin) {
			padding: rem-calc(208 0 450 0);
		}
		@include bp(tabletmd) {
			padding: rem-calc(104 0 200 0);
		}
		@include bp(tablet) {
			padding: rem-calc(104 0 200 0);
			// min-height: 850px;
	
		}
		@include bp(xsm) {
			padding: rem-calc(104 0 200 0);
			// min-height: 850px;
		}
	}
	
	.banner-bottom-spacing {
		margin-bottom: 0;
		padding-bottom: rem-calc(54);
	}

	.bnr_innertxt{
		display:flex;
		flex-wrap:wrap;
		align-content:center;
		justify-content:center;
		min-height:rem-calc(467px);
		margin:rem-calc(17px) auto 0;
		max-width: rem-calc(960px);
		position: relative;
		z-index: 1;


			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				/*padding-top:10%;*/
			}


		@include bp(lg) {
			min-height:rem-calc(300px);			
		}

		.tagline{
			width: 100%;
			font-weight: $bold;
			color: $white;
			@include bp(lgmin) {
				font-size: rem-calc(36px);
			}
		}

		h1{
			margin-bottom: 6%;
			width: 100%;
		}


		p{
			@extend .bigtxt;			
			font-weight:$bold;
			width: 100%;

		}

		.bnrbtn{
			margin: rem-calc(10px 0 20px);
			@include bp(lgmin) {
				margin: rem-calc(40px 0 0);
			}

			.btn{
				@include bp(lgmin) {
					padding: rem-calc(14px 23px);
				}
			}

		}
	}

	


	&.inner_bnr{

		&.whitetxt{
			.bnr_innertxt{
				p.tagline{
					color: $white;
				}
			}
		}

		&.innerspacing{
			@include bp(lgmin) {
				padding: rem-calc(72px 0 61px);
			}
		}
		.bnr_innertxt{
			min-height: rem-calc(223px);
			max-width: 100%;

			p.tagline{
				color: $darkgrey;
				margin-bottom: rem-calc(21px);
				font-size: rem-calc(22px);
				font-weight: $bold;
				text-transform: uppercase;
			}


			h1{
				font-weight:$heavy;
				text-transform: none;
				margin-bottom: rem-calc(32px);
				line-height: 1.1;
				width: 100%;
				
				@include bp(lgmin) {
					font-size: rem-calc(40px);
				}

				@include bp(lg) {
					font-size:  rem-calc(30);
					margin-bottom: rem-calc(30px);
				}
				@include bp(tabletmd) {
					font-size:  rem-calc(28);
					line-height: normal;
					margin-bottom: rem-calc(20px);
				}
				@include bp(tablet) {
					font-size:  rem-calc(26);
					margin-bottom: rem-calc(15px);
				}
				@include bp(xsm) {
					font-size:  rem-calc(20);
				}
			}

			p{

				@extend .mdtxt;
				font-weight: 400;
				letter-spacing: 0.7px;
				line-height: 1.3;

				@include bp(lgmin) {
					font-size: rem-calc(25px);
				}
			}

			.bnrbtn{
				@include bp(lgmin) {
					margin: rem-calc(3px 0 0);
				}


			}

		}
	}
	
	.secbgimg{    
		position:absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		background-repeat: no-repeat !important;
		background-size: cover !important;

		&.pattern{
			background-repeat: repeat !important;
				background-size: auto !important;
		}
	}
}

.banner-overlay-top {
	// top: -170px !important;
	background-position: center bottom !important;


	@include bp(lg) {
		background-position: center center !important;
	}

	@include bp(tabletmd) {
		background-position: 17% bottom !important;
	}

	@include bp(tablet) {
		background-position: 17% bottom !important;
	}

	@include bp(xsm) {
		background-position: 17% bottom !important;
	}
}

.banner-overlay-img {
	
	@include bp(tabletmd) {
		background-position: left center !important;
	}

	@include bp(xsm) {
		background-position: left center !important;
	}
}


