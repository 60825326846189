.whole-picture-carousel {
    margin-top: -36px;
    .carousel-custom-navigator {
        margin-top: rem-calc(134);
        background: $lightgrey;
        .carousel-dot {
            padding: rem-calc(68 0);
            font-family: $titilliumWeb;
            font-size: rem-calc(16);
            font-weight: $bold;

            &:after {
                content: " ";
                font-size: rem-calc(25);
            }
            
            div.dot-button {
                width: 80%;
                font-size: rem-calc(16);
                font-weight: $bold;
                letter-spacing: 0.8px;
                color: $blacktop;
                display: inline-block;
                padding: rem-calc(6 0) !important;
                text-align: center;
                line-height: normal;
                cursor: pointer;
                text-transform: initial;
                transition: 0.5s all;
                border-radius: 30px;
                border: 2px solid $lightgrey;
                &:hover, &:focus {
                    opacity: 1;
                    border: 2px solid $yellow;
                    color: $blacktop;
                    background: $white;
                }
                &.active {
                    @extend .btn;
                }
            }
        }

        .carousel-dot-item {
            &:after {
                content: ">";
                color: $yellow;
                font-size: rem-calc(25);
                margin-left: 5%;
            }
        }
    }

    .software-button {
        padding-bottom: 20px;
    }

    .owl-nav {
        position: absolute;
        width: 100%;
        // top: 250px !important;
        z-index: 10;
        color: $yellow;
        font-size: 80px !important;
        margin-top: -180px !important;

        @include bp(lg) {
            
        }

        @include bp(tabletmd) {
            
        }
        
        @include bp(tablet) {
            
    
        }

        @media (max-width: 350px) {
            // margin-top: 140px !important;
        }

        button.owl-next {
            position: absolute;
            z-index: 12;
            right: -20%;
            &:hover {
                background: transparent;
                color: $yellow;
                font-size: 82px;
            }
            &.disabled {
                display: none !important;
            }
        }
        button.owl-prev {
            position: absolute;
            z-index: 12;
            left: -20%;
            &:hover {
                background: transparent;
                color: $yellow;
                font-size: 82px;
            }
            &.disabled {
                display: none !important;
            }
        }
    }

    .first-img {
        width: 45% !important;
        margin: auto;
    }
    
    .carousel-img {
        width: auto !important;
        height: 250px;
        margin: auto;
    }

    .carousel-text {
        align-self: center;
    }
}