.floatimg{
	position: relative;

	&.innerspacing{
		
		@include bp(lgmin) {
			padding: rem-calc(99px 0 122px);
		}

		@include bp(sm) {
			padding-top: 0; 
		}
	}

	.pattern{
		background-size: rem-calc(1317px) !important;
		background-repeat: no-repeat!important;
		@include bp(lg) {
			background-size: 100% !important; 
		}
		@include bp(sm) {
			opacity: 0.5;
		}
	}

	.round_peopleimg{
		position: absolute;
		left: rem-calc(-160px);
		top: 0;
		@include bp(lg) {
			left: rem-calc(-120px);
		}
		@include bp(tablet) {
			left: rem-calc(-60px);
		}
		@include bp(sm) {
			position: static;
		}



		.round_person{
			@include bp(lgmin) {
				height: rem-calc(430px);
				width: rem-calc(430px);
			}

			.round_personimg{
				@include bp(lgmin) {
					height: rem-calc(398px);
					width: rem-calc(398px);
				}
			}
		}
	}

	.floatimg_info{
		padding: rem-calc(0 0 0 278px);
		@include bp(xlmin) {
			padding: rem-calc(0 0 0 208px);
		}
		@include bp(lg) {
			padding: rem-calc(0 0 0 200px);
		}
		@include bp(tabletmd) {
			padding: rem-calc(0 0 0 168px);
		}
		@include bp(sm) {
			padding: 0;
		}
		h2{
			@include bp(lgmin) {
				letter-spacing: -0.3px;
				margin-bottom: rem-calc(29px);
			}
		}

		h3{
			font-weight: $light;
			font-style: italic;
			margin: rem-calc(38px 0 33px);
			line-height: 1.4;
			letter-spacing: 1px;

			@include bp(tablet) {
				margin: rem-calc(30px 0 30px);
			}

			@include bp(sm) {
				margin: rem-calc(20px 0 20px);
			}
		}

		p{
			@extend .mdtxt;
		}
	}

	&.image_pad_top{
		.round_peopleimg{
			top: auto;

			@include bp(lgmin) {
				margin: 30px 0 0;
			}
		}

		.pattern{
			background-size: auto!important; 
		}
	}

}
