  
.core_value{
	overflow: visible;
	position: relative;
	z-index: 2;
	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(100px 0 105px);
		}
	}

	.secbgimg.pattern {
		background-repeat: no-repeat !important;
		background-size: auto!important;
		bottom: -4px;
		top: auto;
		max-height: 100%;
	}

	.top_txt{
		@include bp(lgmin) {
			padding: rem-calc(0 0 0 76px);
		}

		h2{
			text-transform: uppercase;
			@include bp(lgmin) {
				margin: rem-calc(0 0 40px 0);
			}
		}

		h3{
			font-weight: $light;
			text-transform: uppercase;
			@include bp(lgmin) {
				font-size: rem-calc(32px);
				line-height: 1.65;
				padding: rem-calc(0 62px 0 0);
			}
		}
	}

	.round_peopleimg{
		text-align: left;
		margin: rem-calc(0 0 -43px 0);

		@include bp(lgmin) {
			padding: rem-calc(0 0 0 54px);
		}

		@include bp(tabletmd) {
			margin: rem-calc(30px 0 0 0);
		}

		@include bp(xsm) {
			margin: rem-calc(20px 0 0 0);
		}

		.round_person{
			margin: 0 ;

			@include bp(lgmin) {
				width: rem-calc(398px);
				height: rem-calc(398px);

				.round_personimg{
					height: rem-calc(368px);
					width: rem-calc(368px);
				}
			}
		}
	}
	
	.large-6{
		display: flex;
		flex-wrap: wrap;
	}

	.core_valueimgbox{
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;

		@include bp(tabletmd) {
			    align-content: flex-start;
		}
	}

	.core_valuetxtbox{
		margin: 14% 0 12%;

		@include bp(sm) {
			margin: rem-calc(20px 0);
		}
	}

	.core_valuetxt{
		margin-bottom: calc(20px + 4.6%);
		max-width: rem-calc(530px);

		@include bp(sm) {
			margin-bottom: rem-calc(20px);
		}
		
		h3{
			margin-bottom: 8px;
			text-transform: uppercase;
			@include bp(lgmin) {
				font-size: rem-calc(24px);
				letter-spacing: 1px;
			}
		}

		p{
			font-size: rem-calc(18px);
			line-height: 1.4;
			@include bp(sm) {
				font-size:  rem-calc(16);

			}
			
		}
	}

	

}
