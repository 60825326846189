  
.overflowimg_text{
	overflow: visible;
	&.innerspacing{	
		margin: rem-calc(74px 0 0);
		@include bp(lgmin) {
			padding:rem-calc(110px 0 66px 0); 
		}
	}

	.secbgimg.pattern{
		background-repeat: no-repeat !important;
		top: rem-calc(-60px);
	}

	.large-4{
		text-align: right;
		@include bp(sm) {
			text-align: center;
		}
		.round_peopleimg{
			display: inline-block;
		}
	}

	.round_peopleimg{
		&.topover_flowedimg{
			top: rem-calc(-185px);
			position: relative;
			@include bp(lg) {
				top: rem-calc(-135px);
			}
			@include bp(tabletmd) {
				top: rem-calc(-115px);
			}
			@include bp(tablet) {
				top: rem-calc(-77px);
			}
			@include bp(sm) {
				top: rem-calc(-7px);
				margin-top: rem-calc(-74px);
			}
		}
	}

	.overimgtext_info{
		h2{
			@include bp(lgmin) {
				margin-bottom: rem-calc(40px);
			}
		}

		p{
			margin-top: rem-calc(32px);
			letter-spacing: 1.09px;

			@include bp(tabletmd) {
				margin-top: rem-calc(26px);
			}
		}

		.overimgtext_btn{
			@include bp(lgmin) {
				margin: rem-calc(42px 0 20px);
				.btn{
					padding: rem-calc(10px 32px); 
				}
			}
		}
	}


	&.leftbigimg{
		.round_peopleimg.topover_flowedimg{
			position: absolute;
			/*top: -79px;*/
			top: -52px;
			left: 7px;

			@include bp(tablet) {
				top: -29px;
			}

			@include bp(sm) {
				position: static;
			}

			> a{
				&:hover{
					opacity: 1;
				}
			}
		}
		@include bp(lgmin) {
			.round_peopleimg.roundside_txt {
				.round_person{
					width: 399px;
					height: 399px;
					position: absolute;
					left: 0;

					.round_personimg{
						width: 366px;
						height: 366px;
					}
				}
			}
		}
	}	

	

}


