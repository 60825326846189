 
.subcircel_icon{
	
	.top_txt{
		margin-bottom: rem-calc(20px);
	}

	.secbgimg.pattern{
		background-repeat: no-repeat !important;  
	}

	.sbcircel_box{
		position: relative;
		padding: rem-calc(16px 0 59px 102px);
		max-width: rem-calc(525px);

		@include bp(tablet) {
			padding:rem-calc( 8px 0 40px 82px);
		}
		@include bp(sm) {
			padding: rem-calc(5px 0 20px 0);
			text-align: center;
			margin: 0 auto;
		}

		> a{
			color: $darkgrey	;
			&:hover{
				opacity: 1;
			}
		}

		.sb_circelico{
			position: absolute;
			left: 0;
			top: 0;
			height: rem-calc(74px);
			width: rem-calc(74px);
			border-radius: 50%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding: 4px;

			@include bp(tablet) {
				height: rem-calc(64px);
				width: rem-calc(64px);
			}
			@include bp(sm) {				
				position: static;
				margin: 0 auto rem-calc(10px);
			}

			img{
				max-height: 100%;
			}
		}

		h3{
			font-weight: $heavy;
			@include bp(lgmin) {
				font-size: rem-calc(25px);
				letter-spacing: 0.4px;
				margin-bottom: rem-calc(3px);
			}
		}

		p{
			line-height: 1.5;
			@include bp(lgmin) {
				margin-bottom: rem-calc(17px);
			}
		}

		&:hover{
			h3{
				color: $orange!important;
			}

			.btn{
				background: $yellow;
				color: $white;
			}
		}
	}

	
}



