 

.moreclient {
	&.innerspacing{
		@include bp(lgmin) {
			padding: rem-calc(87px 0 61px);
		}
	}

	.moreclient_sliditem{
		padding: rem-calc(0 15px);


	}

	.moreclient_slider{
		margin:rem-calc( 0 60px);

		@include bp(xlmin) {
			margin: 0;
		}

		@include bp(sm) {
			margin: rem-calc(0 50px);
		}
		@include bp(xsm) {
			margin: rem-calc(0 30px);
		}

		.slick-list{
			margin: rem-calc(0 -15px);
		}

		.slick-prev:before, .slick-next:before{
			background: url(../images/icons/rsleftarrow.png) center no-repeat;
			height: rem-calc(45px);
			width: rem-calc(23px);
			content: "";
			display: inline-block;

			@include bp(sm) {
				height: rem-calc(25px);
			} 
		}

		.slick-next:before{
			transform:rotate(-180deg);
		}

		.slick-prev{
			left: rem-calc(-57px);
			top: rem-calc(119px);
			@include bp(sm) {
				left:rem-calc( -25px);
				
			}
			@include bp(xsm) {
				top: rem-calc(90px);
			}
		}
		.slick-next{
			right: rem-calc(-52px);
			top: rem-calc(120px);			
			@include bp(sm) {
				right:rem-calc( -25px);
			}
			@include bp(xsm) {
				top: rem-calc(90px);
			}
		}
	}

	.mjpost_box{
		padding: rem-calc(23px 0 56px);

		@include bp(tablet) {
			padding: rem-calc(23px 0 36px);		

		}
		@include bp(xsm) {
			padding: rem-calc(23px 0 26px);

		}

		.mjpost_img{
			padding: 0;
			overflow: hidden;
			transition:0.5s all;

			&:hover{
				figure{
					transform: scale(1.05);
				}

			}

			figure{
				transition:1.5s all;
				@include bp(lgmin) {
					padding: 0 0 37% 0;
				}
			}
		}

		.mjpost_txt{
			@include bp(lgmin) {
				margin: rem-calc(31px 0px);

				.read_btn{
					bottom: rem-calc(26px);
				}
			}
		}
	}
}

