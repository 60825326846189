
.benefit-listing-block{
	&.innerspacing{				
		@include bp(lgmin) {
			padding: rem-calc(75px 0 25px);
		}
	}	    
    ul{
        &.benefit-listing{
            padding-top:rem-calc(25);
            li{
                display:block;
                text-align:center;
                .benefit-thumb{
                    width:rem-calc(160);
                    height:rem-calc(75);                    
                    margin:0 auto rem-calc(18);
                    img{
                        display: block;
                    }
                }
                .benefit-desc{
                    display: block;                    
                    h4{
                        font-weight:$normal;                        
                    }
                }
            }
        }
    }
}
