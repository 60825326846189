  
.icon-with-content-block{
   &.innerspacing{
        @include bp(lgmin) {
            padding: rem-calc(80px 0 30px); 
        }
    }
    .img-thumb{
        max-width:rem-calc(280px);
        width: 100%;
        display: inline-block; 
       

        @include bp(lgmin) {
            position: relative;
            top: rem-calc(-14px);
        }
        @include bp(sm) {
            margin: rem-calc(10px) auto 0;
            display: block;
            max-width: rem-calc(180px);
        }
    }

    .large-4{
        text-align: right;
        @include bp(tabletXl) {
            text-align: center;
        }
    } 

    .icotxt_row:nth-child(even){
        flex-flow: row-reverse; 
        padding-top: rem-calc(10px);

        @include bp(sm) {
            flex-flow: wrap;
            padding: 0;
        }

        .large-4{
            text-align: left;
            @include bp(lgmin) {
                padding: rem-calc(30px 0 0 50px);
            }            
        }
    }
    .icotxt_row:last-child{
        .large-8{
            .content-wrap{
                padding-bottom: rem-calc(50px);
                @include bp(tabletXl) {
                    padding-bottom: rem-calc(30px);
                }
                @include bp(sm) {
                    padding-bottom: 0;
                }
            }
        }
    }
    .content-wrap{

        @include bp(sm) {
            padding: rem-calc(20px 0 0);
        }

        @include bp(lgmin) {
            padding: rem-calc(36px 0 0 1px);
            p{
                letter-spacing: 1px;
            }
        }
        h2{
            margin-bottom: rem-calc(9px);
            letter-spacing: -0.2px;
        }
    }
}
