
.new-statistics{

	.stats-body {
		padding-bottom: rem-calc(57);
	}

	.stats-heading {
		font-family: $titilliumWeb;
		font-weight: $bold;
		font-size: rem-calc(88);
		line-height: rem-calc(95);
		color: $green;
		padding-bottom: rem-calc(5);

		@include bp(lg) {
			// height: 100px;
		}
		@include bp(tabletmd) {
			font-size: rem-calc(74);
			line-height: rem-calc(80);
		}
		@include bp(tablet) {
			font-size: rem-calc(74);
			line-height: rem-calc(80);
	
		}
		@include bp(xsm) {
			font-size: rem-calc(58);
			line-height: rem-calc(80);
		}
	}

	.stats-title {
		font-family: $titilliumWeb;
		font-weight: $bold;
		font-size: rem-calc(32);
		line-height: rem-calc(34);
		color: $green;
		padding-bottom: rem-calc(20);

		@include bp(lg) {
			// height: 100px;
		}
		@include bp(tabletmd) {
			font-size: rem-calc(26);
			line-height: rem-calc(28);
		}
		@include bp(tablet) {
			font-size: rem-calc(26);
			line-height: rem-calc(28);
	
		}
		@include bp(xsm) {
			font-size: rem-calc(22);
			line-height: rem-calc(28);
		}
	}

	.stats-subtitle {
		font-family: $titilliumWeb;
		font-weight: $bold;
		font-size: rem-calc(17);
		line-height: rem-calc(20);
		color: $yellow;
		padding: 10px 0;

		@include bp(lg) {
			// height: 100px;
		}
		@include bp(tabletmd) {
			font-size: rem-calc(14);
			line-height: rem-calc(19);
		}
		@include bp(tablet) {
			font-size: rem-calc(14);
			line-height: rem-calc(19);
	
		}
		@include bp(xsm) {
			font-size: rem-calc(14);
			line-height: rem-calc(19);
		}
	}

	.light-bulb-icon {
		max-height: 250px;

		@include bp(lg) {
			max-height: 200px;
		}
		@include bp(tabletmd) {
			max-height: 150px;
		}
		@include bp(tablet) {
			max-height: 180px;
	
		}
		// @include bp(xsm) {
		// 	max-height: 50px;
		// }
	}

	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(91px 0 80px);
		}
	}

	.top_txt{
		margin-bottom: rem-calc(30px);
		@include bp(lgmin) {
			margin-bottom: rem-calc(37px);
		}

		h2{
				margin-bottom: rem-calc(6px);	
				text-transform: uppercase;		
		}


	}

	.pattern{
		bottom: 0;
		height: 100%;
		background-repeat: no-repeat!important;
		@include bp(lg) {
			background-size: 73% !important; 
		}

		@include bp(sm) {
			display: none;
		}
	}

	.top_stattxt{
		h2{
			@extend .h2big;
			margin-bottom: 0;
		}
		p{
			font-weight: $bold;
			margin-top:rem-calc( -7px);
			@include bp(lg) {
				margin-top: rem-calc(-3px);
			}
		}
	}

	.statistics_map{
		padding: rem-calc(105px 0 50px);
		position: relative;
		z-index: 1;

		@include bp(xsm) {
			padding: rem-calc(50px 0 50px);
		}

		.statistics_mapbg {
			position: absolute;
			top:0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -1;
			background-size: auto !important;
		}

		
	}

	.statistics_nums{
		display: flex;
		flex-wrap:wrap;
		justify-content: space-around;
		max-width: rem-calc(944px);
			margin: 0 auto;

			@include bp(xsm) {
				display: block;
			}
			

		.sldata-counter{
			width: rem-calc(176px);
			height: rem-calc(176px);
			background: $yellow;
			border-radius: 50%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			align-content: center;
			padding: rem-calc(20px);
			color: $white;
			margin: rem-calc(0 20px);

			@include bp(tabletmd) {
				width: rem-calc(146px);
				height: rem-calc(146px);
			}

			@include bp(sm) {
				width: rem-calc(116px);
				height: rem-calc(116px);
				margin: rem-calc(10px 10px);
			}

			@include bp(xsm) {
				margin: rem-calc(15px) auto;
			}




			h3{
				color: $white;
				@extend .h2big;
				margin-bottom: 0;

			}

			span{
				width: 100%;
				display: block;
				text-align: center;
				font-weight: bold;
				margin: rem-calc(11px 0 0);

				@include bp(lg) {
					margin: 0;
				}

				@include bp(sm) {
					font-size: rem-calc(14px);    
				}
			}
		}
	}
	

	

}


