  
.logoslider{

	&.innerspacing{	
		padding: rem-calc(20px 0 19px);
	}

	.logoslider_inner{
		display: flex;
		flex-wrap: wrap;

		.logoslid_txt{
			width: rem-calc(275px);
			text-align: center;
			@include bp(lg) {
				width: rem-calc(245px);
			}
			@include bp(tabletmd) {
				width: rem-calc(200px);
			}
			@include bp(sm) {
				width: 100%;				
			}
			h3{
				color: $white;
				margin: 0;
				padding: rem-calc(13px 0);
				border-right:1px solid $white;
				@include bp(sm) {
					border-right: 0;
					padding-top: 0;
				}
			}
		}

		.logoslidebar{
			width: calc(100% - 275px);
			padding: rem-calc(5px 0 0 38px);

			@include bp(lg) {
				width: calc(100% - 245px);
			}
			@include bp(tabletmd) {
				width: calc(100% - 200px);
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				align-content: center;
			}

			@include bp(sm) {
				width: 100%;
				padding: rem-calc(10px 0 0);
			}

			.logoslidebar_item{
				padding:rem-calc( 0 5px);
			}
		}
	}
	

}
