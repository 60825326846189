  
.blog_detail{
	max-width: 860px;
	margin: 0 auto;
	&.innerspacing{		
		@include bp(lgmin) {
			padding: rem-calc(62px 0 105px);
		}
	}

	.date_tag{
		text-align: right;
		

		time{
			display: inline-block;
			vertical-align: top;
			line-height: 1.4;
			font-size: rem-calc(16px);
			padding: rem-calc(0 10px 0 0);
		}

		.byline{
			display: inline-block;
			vertical-align: top;
			line-height: 1.4;
			font-size: rem-calc(16px);
			margin-bottom: rem-calc(11px);
			position: relative;
			padding: rem-calc(0 0 0 10px);
			letter-spacing: -0.3px;
			text-transform: capitalize;

			&:before{
				content: "";
				display: inline-block;
				position: absolute;
				background: $darkgrey;
				height: rem-calc(16px);
				width: 1px;
				left: 0;
				top: rem-calc(4px); 
			}

			a{
				color: $darkgrey; 
			}
		}
	}

	p{
		line-height: 1.4;
		margin-bottom: rem-calc(11px);
	}

	img{
		margin-bottom: rem-calc(38px);

		@include bp(sm) {
			margin-bottom: rem-calc(20px);
		}
	}

	ul{
		@extend .bulletlist;
	}

	blockquote{
		padding: 0;
		border: none;
		font-style: italic;
		font-size: rem-calc(24px);
		@include bp(tabletmd) {
			font-size:  rem-calc(21);
			line-height: normal;
		}
		@include bp(tablet) {
			font-size:  rem-calc(20);		

		}
		@include bp(xsm) {
			font-size:  rem-calc(18);			
		}

		> p{
			position: relative;
			letter-spacing: 0.1px;

			&:after,&:before{
				content: "";
				display: inline-block;
			//position: absolute;
		}
		&:before{
			content: "\“";
			
		}
		&:after{
			content: "\”";
		}
		}
	}

	.catogerylist{
		color: $orange;
		label{
			font-size: rem-calc(20px);
			display: inline-block;
			vertical-align: top;
			line-height: 1.5;
			color: $darkgrey;
		}

		a{
			display: inline-block;
			vertical-align: top;
			line-height: 1.5;
			padding:rem-calc(0 5px);
			color: $orange;			
		}
	}
	

}

.blogarrow_box{
	/*display: flex;
	flex-wrap:wrap;
	justify-content: space-between;*/

	.btn{
		border-radius: 0;
	}

	.nav-links{
		display: flex;
		flex-wrap:wrap;
		justify-content: space-between;
		position: relative;
		padding: rem-calc(0 0 41px 0);

		.nav-previous{
			a .meta-nav{
				position: absolute;
				left:0;
				@extend .btn;
				@extend .ghostbtn;

			}
		}

		.nav-next{
			a .meta-nav{
				position: absolute;
				right:0;
				@extend .btn;
				@extend .ghostbtn;

			}
		}
	}
}

.related_posts{
	h2{
		color: $orange;
	}

	.large-6 {
		display: flex;
		flex-wrap: wrap;
	}

	.mjpost_box{
		padding-top: 20px;

		.mjpost_topline{
			text-transform: capitalize;
		}
	}
}


.single .footer_mail{
	@include bp(tabletmd) {
		padding: rem-calc(0 0 50px 0);
	}
}