.resource_slider {
	&.innerspacing {
		@include bp(lgmin) {
			padding: rem-calc(112px 0 65px);
		}
	}

	.top_txt {
		margin-bottom: rem-calc(47px);

		h2 {
			@include bp(lgmin) {
				margin-bottom: rem-calc(9px);
			}
		}
	}

	.slick-prev:before, .slick-next:before {
		background: red;
	}

	/* slide when not active/center*/
	.slick-slide,
	.slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
		transform: scale(1, 1);
		transition: transform 0.3s;
		background: $darkgrey;
	}

	/* slide when active/center */
	.slick-center,
	.slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
		transform: scale(1.25, 1.25);
		position: relative;
		z-index: 1;
		background: $orange;

		@include bp(tabletmd) {
			transform: scale(1, 1);
		}

		@include bp(sm) {
			background: $darkgrey;
		}

		.rsslide_boxinfo {
			opacity: 1 !important;

			.postname {
				background: $yellow !important;
			}
		}

		.rsslide_box {
			.rsslide_img {
				filter: grayscale(0) !important;
			}
		}
	}
}

.resource_sliderbox {
	margin: rem-calc(0 63px);

	@include bp(sm) {
		margin: rem-calc(0 26px);
	}

	.slick-prev:before, .slick-next:before {
		background: url(../images/icons/rsleftarrow.png) center no-repeat;
		height: rem-calc(45px);
		width: rem-calc(23px);
		content: "";
		display: inline-block;

		@include bp(sm) {
			height: rem-calc(25px);
		}
	}

	.slick-next:before {
		transform: rotate(-180deg);
	}

	.slick-prev {
		top: 47%;
		left: rem-calc(-49px);
		@include bp(xlmin) {
			left: rem-calc(-89px);
		}
		@include bp(sm) {
			left: rem-calc(-25px);
		}
	}

	.slick-next {
		top: 47%;
		right: rem-calc(-49px);
		@include bp(xlmin) {
			right: rem-calc(-82px);
		}
		@include bp(sm) {
			right: rem-calc(-25px);
		}
	}

	.slick-slide, .slick-slide > div {
		height: auto;
		display: flex;
		flex-wrap: wrap;
	}

	.resource_slideitem {
		height: auto;
		display: inline-flex !important;
		flex-wrap: wrap;

		> a {
			color: $white;
			display: flex;
			flex-wrap: wrap;

			&:hover {
				opacity: 1;
			}
		}

		.rsslide_box {
			display: flex;
			flex-wrap: wrap;

			.rsslide_img {
				padding: rem-calc(0 0 158px 0);
				position: relative;
				z-index: 1;
				width: 100%;
				filter: grayscale(1);
			}

			.rsslide_boxinfo {
				color: $white;
				position: relative;
				opacity: 0.85;
				padding: rem-calc(35px 20px 20px 38px);

				@include bp(lg) {
					padding: rem-calc(35px 10px 20px 20px);
				}

				.postname {
					position: absolute;
					height: rem-calc(140px);
					width: rem-calc(140px);
					display: flex;
					flex-wrap: wrap;
					border-radius: 50%;
					top: rem-calc(-108px);
					right: rem-calc(-4px);
					background: #898786;
					align-items: flex-end;
					justify-content: center;
					font-size: rem-calc(11px);
					font-weight: $bold;
					color: $darkgrey;
					text-transform: uppercase;
					padding: rem-calc(11px 10px);
				}

				.tagline {
					color: $white;
					margin-bottom: 0;
					font-weight: $bold;
					line-height: rem-calc(28);

					@include bp(lg) {
						font-size: rem-calc(19);
					}
					@include bp(tabletmd) {
						font-size: rem-calc(18);
					}
					@include bp(tablet) {
						font-size: rem-calc(17);
						line-height: rem-calc(24);

					}
					@include bp(xsm) {
						font-size: rem-calc(16);
						line-height: rem-calc(21);
					}
				}

				.rspost_view {
					position: absolute;
					bottom: rem-calc(10px);
					right: 0;

					a, span {
						position: relative;
						color: $white;
						font-size: rem-calc(13px);
						text-transform: uppercase;
						font-weight: $bold;

						&:after {
							content: "►";
							display: inline-block;
							vertical-align: top;
							padding: rem-calc(6px 10px 0 5px);
							@include bp(tabletmd) {
								padding: rem-calc(3px 10px 0 5px);
							}
							@include bp(sm) {
								padding: rem-calc(2px 10px 0 5px);
							}
						}
					}
				}
			}

			.rs_disc {
				color: $white;
				font-weight: $normal;

				@include bp(lgmin) {
					font-size: rem-calc(19px);
					margin-bottom: rem-calc(4px);
				}

				@include bp(lg) {
					font-size: rem-calc(16px);
				}
			}

			.rspost_txt {
				p {
					font-size: rem-calc(14px);
					line-height: 1.5;
				}
			}
		}
	}

	.slick-slide {
		&.slick-current {
			background: $orange;
			@include bp(sm) {
				background: $darkgrey;
			}

			.rsslide_box {
				/*background: $orange;*/
				position: relative;
				z-index: 1;
				overflow: hidden;

				.rsslide_img {
					@include bp(tabletmd) {
						filter: grayscale(0) !important;
					}
				}
			}

			.rsslide_boxinfo {
				@include bp(tabletmd) {
					opacity: 1 !important;
					.postname {
						background: $yellow !important;
					}
				}
			}
		}
	}

	.slick-list {
		padding: 53px 0 53px !important;

		@include bp(tabletmd) {
			padding: 0 !important;
			max-width: rem-calc(600px);
			margin: 0 auto;
		}

		.slick-track {
			display: flex;
			flex-wrap: wrap;

			.slick-slide:last-child {
				display: none;
			}
		}
	}
}

.resource_btn {
	margin-top: 30px;
}
