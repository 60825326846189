.faq-block{ 
	&.innerspacing{
		@include bp(lgmin) {
			padding:rem-calc(80px 0 76px);
		}
	}
	.top_txt{
		margin:0 0 0 rem-calc(75px);
		max-width:rem-calc(1000px);
		@include bp(tablet) {
			margin:0;
		}
		h2{
			text-transform: uppercase;
			margin-bottom: rem-calc(10px);
			
		}		
	}

	.accordion{
		.accordion-item{
			padding: rem-calc(0 0 0 75);
			position: relative; 
			@include bp(tablet) {
				padding: rem-calc(0 0 0 55px);
			}    
			@include bp(sm) {
				padding: rem-calc(0 0 0 31px);
			}       
            &:after{
                content:"";
                position:absolute;
                width:calc(100% - 75px);
                height: rem-calc(1);
                background:#738592;
                right:0;
                bottom:0;

                @include bp(tablet) {
                	width: calc(100% - 55px);
                }    
                @include bp(sm) {
                	width: calc(100% - 31px);
                }
            }
			
			.accordion-title{
				border:none;
				letter-spacing: 0.6px; 
				margin: 0;
				position: static;				
				padding: rem-calc(35 0 8);
				@include bp(sm) {
					padding: rem-calc(15 0 3px);
					font-size: rem-calc(20); 
				}
				&:hover , &:focus{
					background: none;
					opacity: 1;
				}


				&:before{
					content: '';
					background: url(../images/icons/pluse.svg) center no-repeat;
					width: rem-calc(45);
					height: rem-calc(45);
					background-size: 100%;
					left: 0;
					top: rem-calc(32); 
					transition:0.5s all;

					@include bp(tabletmd) {
						top:rem-calc(55);
						width: rem-calc(38);
						height: rem-calc(38);
					}

					@include bp(tablet) {
						top:rem-calc(38);
						width: rem-calc(33);
						height: rem-calc(33);
					}

					@include bp(sm) {
						top:rem-calc(23);
						width: rem-calc(23);
						height: rem-calc(23);
					}
					
				}

				h4{					
                    font-size:rem-calc(22);
					font-weight: $bold;					
					@include bp(lgmin) {
						font-size: rem-calc(24);
						margin-bottom: rem-calc(17);				
					}
					@include bp(lg) {
						font-size:  rem-calc(23);
						line-height: rem-calc(32);
					}
					@include bp(tabletmd) {
						font-size:  rem-calc(21);
						line-height: normal;
						letter-spacing: 1px;
					}
					@include bp(tablet) {
						font-size:  rem-calc(20);		

					}
					@include bp(xsm) {
						font-size:  rem-calc(18);			
					}
				}
			}

			.accordion-content{
				padding-top: 0;
				padding-bottom: rem-calc(21);
                padding-left:0;
				p{
					line-height: 1.4;
					margin-bottom: rem-calc(10);
				}	

				ul{
					@extend .orangetick_list;
					margin: rem-calc(17 0 0 2);
				}               
					
			}


			&.is-active{
				.accordion-title{
					margin-bottom: -5px;
					padding: rem-calc(50 0 0);

					@include bp(tablet) {
						padding: rem-calc(47 0 0);
					}
					@include bp(sm) {
						padding: rem-calc(27 0 0);
					}
					&:before{						
						background: url(../images/icons/minus.svg) center no-repeat;
						background-size: 100%;
						top: rem-calc(45);	
						@include bp(sm) {
							top: rem-calc(36);
						}			
					}
					.acc_icon{
						top: rem-calc(17);
						@include bp(sm) {
							top: rem-calc(26);
						}
					}
				}			
			}
		}

		.accordion-content{
			border:none;
		}
	}
	
}

.open_position-template-default .at-share-dock-outer.addthis-smartlayers.addthis-smartlayers-mobile {
    display: none;
}

.open_position-template-default .addthis-smartlayers.addthis-smartlayers-desktop {
    display: none;
}